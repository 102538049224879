<script>
  import { filter5 } from './stores2'
  import { _ } from 'svelte-i18n'

  export let group
  export let prop
</script>

<style>
  tspan {
    fill: orange;
    font-size: 12px;
    font-style: italic;
    cursor: pointer;
  }
</style>

{#if $filter5[group][prop]}
  <tspan
    dx={3}
    dy={-1}
    on:click={() => {
      let tmp = $filter5
      tmp[group][prop] = undefined
      $filter5 = tmp
    }}>
    {$_('clear')}
  </tspan>
{/if}
