<script>
  import { links2, filter5, filteredLinks5 } from './stores2'
  import { getContext } from 'svelte'
  import { group } from 'd3-array'
  import { stacked } from './common'
  import TweenedRect from './TweenedRect.svelte'
  import { _ } from 'svelte-i18n'

  const width = getContext('width')

  const RATIO = 1
  const MIN_HEIGHT = 24

  $: itemCount = group(
    $filteredLinks5.counter.filter((d) => d.answer),
    (d) => d.answer
  )

  $: originalItems = group(
    $links2.counter.filter((d) => d.answer),
    (d) => d.answer
  )

  $: items = stacked(originalItems, itemCount)
</script>

<style>
  text {
    font-size: 12px;
    dominant-baseline: middle;
    text-anchor: middle;
    user-select: none;
  }

  tspan {
    font-style: italic;
  }

  rect {
    stroke-width: 2;
    stroke: rgba(0, 21, 30);
    opacity: 0.3;
  }

  .item {
    cursor: pointer;
  }
</style>

<g>
  {#each items as item, i}
    <g
      class="item"
      on:click={() => {
        let tmp = $filter5
        tmp.counter.answer = tmp.counter.answer === item[0] ? undefined : item[0]
        $filter5 = tmp
      }}>
      <rect
        y={item.y0}
        {width}
        height={item.y1 - item.y0}
        fill={!itemCount.has(item[0]) ? '#061e28' : $filter5.counter.answer === item[0] ? 'orange' : 'rgb(9 60 125)'}
        rx={5}
        ry={5} />
      <TweenedRect
        pct={item.pct}
        {item}
        {width}
        fill={!itemCount.has(item[0]) ? '#061e28' : $filter5.counter.answer === item[0] ? 'orange' : 'rgb(9 60 125)'} />
      <text
        x={width / 2}
        y={item.y0 + (item.y1 - item.y0) / 2}
        fill={itemCount.has(item[0]) ? 'white' : 'rgba(255, 255, 255, 0.2)'}>
        {$_(item[0])}{' '}
        <tspan>
          {itemCount.has(item[0]) ? itemCount.get(item[0]).length : ' '}
        </tspan>
      </text>
    </g>
  {/each}
</g>
