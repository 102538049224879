<script>
  import { library } from './../stores2.js'
  import { groups } from 'd3-array'
  import { scaleOrdinal } from 'd3-scale'
  import { color } from 'd3-color'
  import { _ } from 'svelte-i18n'

  $: books = []

  $: domains = [
    { key: 'VA', label: 'Various' },
    { key: 'DI', label: 'Dictionary' },
    { key: 'LI', label: 'Literature (prose fiction, drama, poetry)' },
    { key: 'AC', label: 'Art & culture general' },
    { key: 'LC', label: 'Language and communication' },
    { key: 'P', label: 'UNKNOWN' },
    { key: 'EP', label: 'Epistemology' },
    { key: 'PS', label: 'Psychology & Psychotherapy' },
    { key: 'PG', label: 'Philosophy general & Philosophy of science' },
    { key: 'SC', label: 'Science general & philosophy of science' },
    { key: 'DE', label: 'Didactics & education' },
    { key: 'SO', label: 'Social science' },
    { key: 'CY', label: 'UNKNOWN' },
    { key: 'ME', label: 'Media studies & communication studies' },
    { key: 'CS', label: 'Cybernetics & systems theory' },
    { key: 'LG', label: 'UNKNOWN' },
    { key: 'SM', label: 'Skiing & mountaineering' },
    { key: 'MA', label: 'Mathematics & math didactics' },
    { key: 'BI', label: 'Biology' },
    { key: 'AI', label: 'UNKNOWN' },
    { key: 'NS', label: 'Neuroscience' },
    { key: 'LE', label: 'UNKNOWN' },
    { key: 'AS', label: 'Astronomy' },
    { key: 'HI', label: 'History' },
    { key: 'CO', label: 'Computer science' },
    { key: 'PH', label: 'Physics' },
    { key: 'MS', label: 'UNKNOWN' },
  ]

  const domainMap = {
    MA: 'Mathematics & math didactics',
    PH: 'Physics',
    PS: 'Psychology & Psychotherapy',
    BI: 'Biology',
    AI: 'Artificial Intelligence',
    HI: 'History',
    CO: 'Computer science',
    AS: 'Astronomy',
    NS: 'Neuroscience',
    PG: 'Philosophy general & Philosophy of science',
    LC: 'Language and communication',
    EP: 'Epistemology',
    SC: 'Science general & philosophy of science',
    DE: 'Didactics and education',
    SO: 'Social science',
    CS: 'Cybernetics & systems theory',
    ME: 'Media studies & communication studies',
    LI: 'Literature (prose fiction, drama, poetry)',
    AC: 'Art & culture general',
    SM: 'Skiing & mountaineering',
    VA: 'Various',
    DI: 'Dictionary',
  }

  const languageMap = {
    E: 'English',
    D: 'German',
    I: 'Italian',
    F: 'French',
    J: 'Japanese',
    L: 'Latin',
  }

  const spacing = 1
  const domainMarkerWidth = 25

  const c = scaleOrdinal()
    .domain(Object.keys(languageMap))
    .range(['#825046', '#3f5e78', '#313c21', '#583e68', '#546c4b', '#452028'])

  const maxWidth = 584

  $: byDomain = !$library
    ? []
    : groups(
        groups($library, (d) => d.title)
          .map((d) => ({
            ...d[1][0],
            author_editor: d[1].map((d) => d.author_editor),
          }))
          .sort((a, b) => a.title.localeCompare(b.title)),
        (d) => d.domain
      ).sort((a, b) => b[1].length - a[1].length)

  $: test = byDomain.reduce((acc, cur) => {
    const x0 =
      acc.length === 0 || acc[acc.length - 1].x1 > maxWidth
        ? 0
        : acc[acc.length - 1].x1 + spacing
    const x1 =
      acc.length === 0 || acc[acc.length - 1].x1 > maxWidth
        ? domainMarkerWidth
        : acc[acc.length - 1].x1 + spacing + domainMarkerWidth
    const y =
      acc.length === 0
        ? 0
        : acc[acc.length - 1].x1 > maxWidth
        ? acc[acc.length - 1].y + domainMarkerWidth
        : acc[acc.length - 1].y

    acc.push({
      type: 'domainMarker',
      domain: cur[0],
      x0,
      x1,
      y,
      width: domainMarkerWidth,
    })

    cur[1].forEach((book) => {
      const x0 =
        acc.length === 0 || acc[acc.length - 1].x1 > maxWidth
          ? 0
          : acc[acc.length - 1].x1 + spacing
      const x1 =
        acc.length === 0 || acc[acc.length - 1].x1 > maxWidth
          ? 3
          : acc[acc.length - 1].x1 + spacing + 3
      const y =
        acc.length === 0
          ? 0
          : acc[acc.length - 1].x1 > maxWidth
          ? acc[acc.length - 1].y + 30
          : acc[acc.length - 1].y

      acc.push({
        ...book,
        type: 'book',
        x0,
        x1,
        y,
        width: 3,
      })
    })

    return acc
  }, [])

  const width = 700
  const height = 250

  const margin = {
    top: 20,
    right: (width - maxWidth) / 2,
    bottom: 20,
    left: (width - maxWidth) / 2,
  }

  $: hoverItem = undefined
  $: hoverDomain = undefined

  const w = width - margin.left - margin.right
  const h = height - margin.top - margin.bottom
  $: console.log(books, domains)

  const barWidth = 20

  $: console.log(byDomain)
</script>

<style>
  .details {
    color: white;
    height: 250px;
    width: 70%;
    max-width: 940px;
    margin: 0 auto;
  }

  .language {
    font-style: italic;
  }

  .author span {
    font-weight: bold;
  }
  .domain {
    text-transform: uppercase;
  }
  .title {
    margin-bottom: 10px;
  }
</style>

<h1 style="color: white;">{$_('library_title')}</h1>
<p style="color: white; max-width: 940px; margin: 0 auto;">
  {$_('library_intro')}
</p>
<div style="width: 90%; margin: 0 auto; max-width: 1200px; margin-top: 100px;">
  <svg viewBox="0 0 {width} {height}">
    <g transform="translate({margin.left}, {margin.top})">
      <g>
        {#each test as item}
          {#if item.type === 'book'}
            <g
              on:mouseover={() => (hoverItem = item)}
              on:mouseout={() => (hoverItem = undefined)}
              transform="translate({item.x0}, {item.y + (hoverItem && hoverItem.title === item.title ? 3 : 0)})
              scale(1, {hoverItem && hoverItem.title === item.title ? 0.9 : 1})">
              <rect
                rx={1}
                ry={1}
                width={item.width}
                height={25}
                stroke="transparent"
                stroke-width={3}
                opacity={!hoverDomain || (hoverDomain && hoverDomain.domain === item.domain) ? 1 : 0.3}
                fill={hoverItem && hoverItem.title === item.title ? color(c(item.language)).brighter(2) : c(item.language)} />
              {#each item.author_editor as author, j}
                <line
                  x2={item.x1}
                  stroke="rgb(1 30 44)"
                  y1={5 + j * 2}
                  y2={5 + j * 2} />
              {/each}
            </g>
          {/if}
          {#if item.type === 'domainMarker'}
            <image
              x={item.x0 - 0}
              y={item.y - 0}
              href="icons/{item.domain}.svg"
              opacity={(hoverDomain && hoverDomain.domain === item.domain) || (hoverItem && hoverItem.domain === item.domain) ? 1 : 0.3}
              width={25}
              height={25}
              on:mouseover={() => (hoverDomain = item)}
              on:mouseout={() => (hoverDomain = undefined)} />
          {/if}
        {/each}
      </g>
    </g>
  </svg>
</div>

<div class="details">
  {#if hoverItem}
    {#if hoverItem.type === 'book'}
      <div class="domain">{domainMap[hoverItem.domain]}</div>
      <h2 class="title">{hoverItem.title}</h2>
      <div class="author">
        by
        <span>{hoverItem.author_editor.join('; ')}</span>
      </div>
      <div
        style="color: {color(c(hoverItem.language)).brighter(2)};"
        class="language">
        {languageMap[hoverItem.language]}
      </div>
    {/if}
  {/if}
  {#if hoverDomain}
    <div class="domain">{domainMap[hoverDomain.domain]}</div>
  {/if}
</div>
