<script>
  import { text } from 'd3-fetch'
  import { fly, fade } from 'svelte/transition'
  import { quintOut } from 'svelte/easing'
  import {
    annotations2,
    selectedText,
    textHeight,
    textElement,
    glasersfeldTexts,
    scrollTop,
    loginAllowed,
  } from './stores2.js'
  import { scaleOrdinal } from 'd3-scale'
  import { onMount, setContext } from 'svelte'
  import Text from './Text.svelte'
  import Annotations from './Annotations.svelte'

  let container
  $: containerHeight = 0

  let closing = false

  onMount(() => {
    containerHeight = container.clientHeight
  })

  $: loaded = false

  const loadText = text(`data/rawtexts/${$selectedText}.html`).then((text) => {
    loaded = true
    return text
  })

  function handleKeydown(e) {
    if (e.code === 'Escape') {
      $selectedText = undefined
    }
  }

  setContext(
    'c',
    scaleOrdinal()
      .domain([
        'Anpassung',
        'Viabilität',
        'Wissen',
        'Vorstellung',
        'Evolution',
        'Erfahrung',
        'Sprache',
        'Lernen',
        'Wirklichkeit',
        'Raum und Zeit',
        'Realität',
        'Wahrnehmung',
      ])
      .range([
        'rgba(20, 184, 184, 0.8)',
        'rgba(20, 102, 184, 0.8)',
        'rgba(43, 43, 239, 0.8)',
        'rgba(102, 20, 184, 0.8)',
        'rgba(184, 20, 184, 0.8)',
        'rgba(184, 20, 102, 0.8)',
        'rgba(184, 20, 20, 0.8)',
        'rgba(184, 102, 20, 0.8)',
        'rgba(102, 184, 20, 0.8)',
        'rgba(184, 184, 20, 0.8)',
        'rgba(20, 184, 20, 0.8)',
        'rgba(20, 184, 102, 0.8)',
      ])
  )

  let handle
  let innerContainer
  let mousepressed = false
  let offset
  let startX

  function handleMouseDown(e) {
    mousepressed = true
    offset = 0
    startX = e.screenX
  }

  function handleMouseMove(e) {
    if (mousepressed) {
      innerContainer.style.width =
        innerContainer.clientWidth - e.movementX + 'px'

      $textHeight = $textElement.scrollHeight
    }
  }

  function handleMouseUp(e) {
    mousepressed = false
  }

  document.onmouseup = handleMouseUp
  document.onmousemove = handleMouseMove
</script>

<style>
  .container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    overflow: scroll;
    display: flex;
    justify-content: center;
    /* box-shadow: -20px 20px 20px rgba(0, 0, 0, 0.6); */
  }

  .inner-container {
    display: grid;
    grid-template-columns: 1fr 200px;
    width: 940px;
  }

  .annotations {
    background-color: #f6f9fb;
  }

  .close {
    position: fixed;
    color: #00151e;
    font-size: 36px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: pointer;
  }

  .close-wrapper {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .drag-handle {
    position: fixed;
    font-weight: 900;
    font-size: 34px;
    padding: 10px;
    color: lightslategray;
    user-select: none;
    cursor: ew-resize;
  }

  .handle-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
  }

  .closing {
    display: none;
  }

  .comment-button {
    position: absolute;
    top: 20px;
    right: 220px;
    background-color: white;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    color: #016c9b;
  }
</style>

<div
  class="container"
  bind:this={container}
  on:scroll={() => ($scrollTop = container.scrollTop)}>

  {#await loadText}
    loading text
  {:then text}

    <div
      class="inner-container"
      bind:this={innerContainer}
      transition:fly={{ y: 600, delay: 250, duration: 800, easing: quintOut }}>
      <div class="raw-text">
        {#if $loginAllowed}
          <button
            class="comment-button"
            on:click={() => alert(`NOT IMPLEMENTED YET...\n Adding comment for title: ${$annotations2.filter((d) => d.page_id === $selectedText)[0].text.title}`)}>
            add comment
          </button>
        {/if}
        <Text {text} />
      </div>
      <div class="annotations">
        {#if $textHeight}
          <Annotations />
        {/if}
      </div>
    </div>

    <div class="handle-wrapper">
      <div
        in:fade={{ delay: 800 }}
        out:fade={{ delay: 250 }}
        class="drag-handle"
        id="drag-handle"
        bind:this={handle}
        on:mousedown={handleMouseDown}>
        ||
      </div>
    </div>

    <div
      class="close-wrapper"
      class:closing
      in:fade={{ delay: 800 }}
      out:fade={{ delay: 250 }}>
      <div
        class="close"
        on:click={() => {
          $selectedText = undefined
          closing = true
        }}>
        <i class="far fa-times-circle" />
      </div>
    </div>
  {:catch error}
    ERROR: {error}
  {/await}

</div>

<svelte:window on:keydown={handleKeydown} />
