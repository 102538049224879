<script>
  import { filteredLinks5, links2, filter5 } from './stores2.js'

  $: personCount = $filteredLinks5.persons.reduce(
    (acc, cur) => acc + cur[1].length,
    0
  )

  let originalPersonCount = Infinity

  $: {
    if (originalPersonCount === Infinity) {
      originalPersonCount  = personCount
    }
  }

  $: persons = $filteredLinks5.persons.map((d) => [
    d[0],
    d[1].length / personCount,
    d[1].length / originalPersonCount
  ])
</script>

<style>
  .person {
    color: hsl(197, 31%, 57%);
    cursor: pointer;
  }

  foreignObject {
    text-align: left;
  }

  .container {
    line-height: 18px;
    padding: 11px 0;
    height: 800px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .selected {
    color: orange;
  }
</style>

<foreignObject width="250" height="1100" y="0" x="0">
  <span class="container">
    {#each persons.sort((a, b) => a[0].localeCompare(b[0])) as person}
      <span
        on:click={() => {
          let tmp = $filter5
          tmp.argumentations.person = tmp.argumentations.person === person[0] ? undefined : person[0]
          $filter5 = tmp
        }}
        class="person"
        class:selected={person[0] === $filter5.argumentations.person}
        style="font-size: {Math.min(10 + person[2] * 300, 20)}px; color: {person[0] === $filter5.argumentations.person ? 'orange' : `hsl(197, 31%, ${27 + person[2] * 2000}%)`};">
        {person[0]},
      </span>
    {/each}
  </span>
</foreignObject>
