<script>
  import { topicList, glasersfeldTexts } from './stores2.js'
  import { arc } from 'd3-shape'
  import { onMount } from 'svelte'

  $: sortedTopicNames = [
    ...new Set(
      $glasersfeldTexts.reduce((acc, cur) => {
        cur.topics.forEach((topic) => acc.push(topic))

        return acc
      }, [])
    ),
  ].sort()

  const width = 35

  const a = arc()
    .innerRadius(0)
    .outerRadius(width)
    .startAngle((d) => (d / sortedTopicNames.length) * Math.PI * 2 + 0.01)
    .endAngle((d) => ((d + 1) / sortedTopicNames.length) * Math.PI * 2 - 0.01)
</script>

<style>
  svg {
    position: absolute;
    top: 20px;
    left: 90px;
  }
</style>

<svg width={2 * width} height={2 * width}>
  <g class="legend" transform="translate({width}, {width})">
    {#if sortedTopicNames.length > 0}
      {#each $topicList as topic, i}
        <clipPath id="clip-path-logo-{i}">
          <circle r={width} />
        </clipPath>
        <path
          d={a(sortedTopicNames.indexOf(topic))}
          clip-path="url(#clip-path-logo-{i})"
          style="fill: hsla({(sortedTopicNames.indexOf(topic) / sortedTopicNames.length) * 360},
          90%, 50%, 0.2)" />
      {/each}
    {/if}
  </g>
</svg>
