<script>
  import { fullCollection } from './../stores2.js'
  import { linkRadial } from 'd3-shape'
  import { _ } from 'svelte-i18n'
  import { wordwrap } from 'd3-jetpack'
  import { dataset_dev } from 'svelte/internal'

  const width = 1200
  const height = 1200

  let selectedLink
  // let selectedSource

  const margin = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20,
  }

  const w = width - margin.left - margin.right
  const h = height - margin.top - margin.bottom

  const c = {
    bestand: 175,
    collections: 200,
    works: 240,
    correspondences: 280,
    lifedocuments: 320,
  }

  $: parentIndices = selectedLink
    ? selectedLink.source
        .ancestors()
        .filter((d) => d.depth === 2)
        .map((d) => d.data.index)
    : []
</script>

{#if $fullCollection}
  <svg {width} {height}>
    <g transform="translate({margin.left}, {margin.top})">

      <g fill="white" transform="translate(200, 50)">
        <text style="font-size: 16;">{$_('overview_title')}</text>
      </g>
      <g transform="translate({w / 2}, {h / 2})">
        {#each $fullCollection.links() as link}
          {#if link.source.depth > 0}
            <g
              on:mouseover={() => {
                if (link.source.depth === 2) {
                  selectedLink = link
                }
              }}
              on:mouseout={() => (selectedLink = undefined)}>
              <path
                d={linkRadial()
                  .angle((d) => (d.x * Math.PI) / 180)
                  .radius((d) => d.y)(link)}
                stroke="transparent"
                stroke-width={4}
                fill="none" />
              <path
                d={linkRadial()
                  .angle((d) => (d.x * Math.PI) / 180)
                  .radius((d) => d.y)(link)}
                stroke={(parentIndices.includes(link.target.data.index) && link.target.depth === 2) || (selectedLink && link.target.data.index === selectedLink.target.data.index) ? 'orange' : `hsla(${c[link.source.data.section]}, 50%, 60%, 0.35)`}
                stroke-width={(parentIndices.includes(link.target.data.index) && link.target.depth === 2) || (selectedLink && link.target.data.index === selectedLink.target.data.index) ? 3 : 1}
                fill="none" />
            </g>
          {/if}
        {/each}
        {#each $fullCollection.descendants() as node}
          <g
            transform="rotate({node.x - 90}) translate({node.y}, 0) rotate({node.x >= Math.PI ? 180 : 0})">
            <circle
              class="level-{node.depth}"
              r={node.depth === 3 ? 1 : Math.sqrt(node.value) * 4}
              fill="hsla({c[node.data.section]}, 50%, 30%, {node.depth === 3 ? 1 : 0.6})"
              stroke="rgb(0 7 23)"
              style="stroke-width: {node.depth === 3 ? 0 : 2}; " />
          </g>
        {/each}
        {#each $fullCollection.descendants() as node}
          {#if node.depth <= 2}
            <g
              transform="rotate({node.x - 90}) translate({node.y}, 0) rotate({(node.x * Math.PI) / 180 >= Math.PI ? 180 : 0})">
              <text
                fill="white"
                style="font-size: 14; text-anchor: {node.depth <= 1 ? 'middle' : (node.x * Math.PI) / 180 >= Math.PI ? 'start' : 'end'};"
                dx={node.depth <= 1 ? 0 : (node.x * Math.PI) / 180 >= Math.PI ? -150 : 150}>
                <!-- {node.data.name.startsWith('Kassette') ? node.data.name.substring(9) : node.data.name} -->
                {#if node.depth === 1}
                  {$_(node.data.name)}
                  <!-- {:else if node.depth === 2}
                  {node.data.children[0].desc_content.length > 50 ? node.data.children[0].desc_content.slice(0, 50) + '...' : node.data.children[0].desc_content} -->
                {/if}
              </text>
            </g>
          {/if}
        {/each}
        <!-- {#if selectedNode} -->
        {#if selectedLink}
          <g>
            <text
              fill="black"
              stroke="black"
              style="text-anchor: middle; stroke-width: 2;">
              {#each wordwrap(selectedLink.target.data.desc_content, 80) as token, i}
                <tspan
                  x={0}
                  y={i * 20}
                  dy={(wordwrap(selectedLink.target.data.desc_content, 80).length / 2) * -10}>
                  {token}
                </tspan>
              {/each}
            </text>
            <text fill="orange" style="text-anchor: middle;">
              {#each wordwrap(selectedLink.target.data.desc_content, 80) as token, i}
                <tspan
                  x={0}
                  y={i * 20}
                  dy={(wordwrap(selectedLink.target.data.desc_content, 80).length / 2) * -10}>
                  {token}
                </tspan>
              {/each}
            </text>
            <text
              transform="rotate({selectedLink.source.x - 90}) translate({selectedLink.source.y},
              0) rotate({(selectedLink.source.x * Math.PI) / 180 >= Math.PI ? 180 : 0})"
              fill="orange"
              style="text-anchor: middle; dominant-baseline: central;">
              #{selectedLink.source.data.name.split(' ')[1]}
            </text>
            <circle
              transform="rotate({selectedLink.source.x - 90}) translate({selectedLink.source.y},
              0) "
              r={selectedLink.source.depth === 3 ? 1 : Math.sqrt(selectedLink.source.value) * 4}
              fill="orange"
              stroke={selectedLink && selectedLink.source.data.index === selectedLink.source.data.index && selectedLink.source.depth === 2 ? 'orange' : 'rgb(0 7 23)'}
              style="stroke-width: {selectedLink.source.depth === 3 ? 0 : 2};
              fill-opacity: 0.3; cursor: pointer;" />
          </g>
        {/if}
      </g>
    </g>
  </svg>
{/if}
