<script>
  import { selectedText, loginAllowed } from './stores2.js'
  import { onMount } from 'svelte'
  import SingleText from './SingleText.svelte'
  import Logo from './Logo.svelte'
  import PasswordProtect from './PasswordProtect.svelte'
  import { _ } from 'svelte-i18n'
  import Home from './routes/Home.svelte'
  import FullCollection from './routes/FullCollection.svelte'
  import Library from './routes/Library.svelte'
  import LanguageSwitch from './LanguageSwitch.svelte'
  import Explanation from './Explanation.svelte'
  import Router, { link, location } from 'svelte-spa-router'

  const files = ['metadata_extract.csv', 'annotation_extract.csv']

  const routes = {
    '/': Home,
    '/full-collection': FullCollection,
    '/library': Library,
  }

  let showExplanation = false

  const width = 2220
  const height = 3800
  const margin = {
    top: 70,
    right: 100,
    bottom: 50,
    left: 20,
  }

  onMount(() => {
    if (!location.search) {
      $loginAllowed = false
    } else {
      const params = location.search
        .replace('?', '')
        .split('&')
        .reduce((acc, cur) => {
          acc[cur.split('=')[0]] = !!+cur.split('=')[1]
          return acc
        }, {})

      if (params.hasOwnProperty('expert-login') && params['expert-login']) {
        $loginAllowed = true
      } else {
        $loginAllowed = false
      }
    }
  })
</script>

<style>
  .title {
    color: white;
    font-size: 3.3rem;
    margin-left: 180px;
    margin-bottom: 0;
    letter-spacing: 0.2rem;
  }

  .sub-title {
    color: #758798;
    margin-left: 180px;
    margin-top: 0;
    font-weight: normal;
    letter-spacing: 0.1rem;
  }

  header {
    height: 100px;
    /* background-color: rgb(0, 21, 30); */
    position: relative;
    text-align: left;
  }

  #outer-container {
    height: 100%;
    width: 100%;
    text-align: center;
  }

  nav {
    position: absolute;
    top: 75px;
    right: 20px;
  }

  .help {
    position: fixed;
    bottom: 10px;
    right: 30px;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    border: none;
    background: rgba(255, 255, 255, 0.3);
    color: white;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .help:hover {
    background: orange;
  }
</style>

<!-- <PasswordProtect> -->
<div id="outer-container">

  <header>
    <h1 class="title">Ernst von Glasersfeld</h1>
    <h2 class="sub-title">{$_('subtitle')}</h2>
    <Logo />
  </header>

  <!-- <Router>
      <nav>
        <Link to="/">Home</Link>
        <Link to="full-collection">{$_('overview')}</Link>
      </nav>
      <div>
        <Route path="full-collection" component={FullCollection} />
        <Route path="/">
          <Home />
        </Route>
      </div>
    </Router> -->
  <nav>
    <a href="/" use:link class:selected={$location === '/'}>Home</a>
    <a
      href="/full-collection"
      use:link
      class:selected={$location === '/full-collection'}>
      {$_('overview')}
    </a>
    <a href="/library" use:link class:selected={$location === '/library'}>
      {$_('library')}
    </a>
  </nav>
  <div>
    <Router {routes} />
  </div>

  <button
    on:click={() => {
      showExplanation = !showExplanation
      if (showExplanation) {
        document.body.style = 'overflow: hidden;'
      } else {
        document.body.style = ''
      }
    }}
    class="help">
    ?
  </button>
  <LanguageSwitch />
</div>

{#if $selectedText}
  <SingleText />
{/if}

{#if showExplanation}
  <Explanation
    on:close={() => {
      showExplanation = false
      document.body.style = ''
    }} />
{/if}
<!-- </PasswordProtect> -->
