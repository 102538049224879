<script>
  import {
    links2,
    filter5,
    filteredLinks5,
    annotations2,
    selectedText,
  } from './stores2.js'
  import { group } from 'd3-array'
  import { wordwrap } from 'd3-jetpack'
  import { fade } from 'svelte/transition'
  import { _ } from 'svelte-i18n'

  $: itemCount = group($filteredLinks5.counter, (d) => d.page_id)

  $: items = Array.from(group($links2.counter, (d) => d.page_id))
    .sort((a, b) => a[1][0].title.localeCompare(b[1][0].title))
    .reduce((acc, cur, i) => {
      const tokens = wordwrap(cur[1][0].title, 50)

      if (i === 0) {
        cur.y0 = 0
        cur.y1 = tokens.length * 20
      } else {
        cur.y0 = acc[i - 1].y1
        cur.y1 = acc[i - 1].y1 + tokens.length * 20
      }

      cur.tokens = tokens

      cur.annotations = $annotations2.filter(
        (d) => d.category === 'ArgumentationFremd' && d.page_id === +cur[0]
      )

      acc.push(cur)

      return acc
    }, [])

  $: isSelected = (annotation) =>
    (annotation.counterArgumentType &&
      $filter5.counter.counter_argument === annotation.counterArgumentType) ||
    (annotation.glasersfeldAnswerType &&
      $filter5.counter.answer === annotation.glasersfeldAnswerType)
</script>

<style>
  text {
    font-size: 12px;
    alignment-baseline: text-before-edge;
    cursor: pointer;
  }

  tspan {
    alignment-baseline: text-before-edge;
  }

  .counter-argument {
    fill: rgb(77 21 51);
  }

  .answer {
    fill: rgb(9 60 125);
  }

  .selected {
    fill: orange;
  }
</style>

<g>
  {#each items as item, i}
    <g>
      <!-- {#if i > 0}
        <line
          y1={-2.5 + item.y0}
          x2={445}
          y2={-2.5 + item.y0}
          stroke="rgba(255, 255, 255, 0.2"
          style="stroke-dasharray: 1 2;" />
      {/if} -->
      <!-- <text
        y={item.y0}
        fill={item[0] === $filter5.counter.page_id ? 'orange' : itemCount.has(item[0]) ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 0.15)'}
        on:click={() => {
          let tmp = $filter5
          tmp.counter.page_id = tmp.counter.page_id === item[0] ? undefined : item[0]
          $filter5 = tmp
        }}>
        {#each item.tokens as token, j}
          <tspan dx={j === 0 ? 0 : 10} x={0} y={item.y0 + j * 16}>
            {token}
          </tspan>
        {/each}
      </text> -->
      <g
        opacity={itemCount.has(item[0]) ? 1 : 0.2}
        transform="translate(0, {i * 22})"
        on:click={() => {
          let tmp = $filter5
          tmp.counter.page_id = tmp.counter.page_id === item[0] ? undefined : item[0]
          $filter5 = tmp
        }}>
        <clipPath id={`clip-path-${i}`}>
          <rect
            width={item.annotations[0].text.length * 0.003 - 3}
            height={22}
            rx={3}
            ry={3} />
        </clipPath>
        <rect
          width={Math.min(item.annotations[0].text.length * 0.003, 400)}
          height={22}
          stroke={$filter5.counter.page_id === item[0] ? 'orange' : 'rgb(0, 21, 30)'}
          fill="transparent"
          opacity={1}
          style="stroke-width: 2;"
          rx={3}
          ry={3} />
        {#each item.annotations as annotation}
          <rect
            class="counter-argument"
            class:selected={itemCount.has(item[0]) && annotation.counterArgumentType && $filter5.counter.counter_argument === annotation.counterArgumentType}
            x={annotation.offsetBegin * 0.003}
            width={(annotation.offsetEnd - annotation.offsetBegin) * 0.003}
            height={20}
            y={1}
            clip-path={`url(#clip-path-${i})`}
            stroke="rgb(0, 21, 30)"
            style="stroke-width: 0.3;" />
          {#if annotation.glasersfeldAnswerType}
            <rect
              class="answer"
              class:selected={itemCount.has(item[0]) && annotation.glasersfeldAnswerType && $filter5.counter.answer === annotation.glasersfeldAnswerType}
              x={annotation.offsetBegin * 0.003}
              width={(annotation.offsetEnd - annotation.offsetBegin) * 0.003}
              height={10}
              y={6}
              clip-path={`url(#clip-path-${i})`}
              stroke="rgb(0, 21, 30)"
              style="stroke-width: 0.3;" />
          {/if}
        {/each}

        {#if $filter5.counter.page_id === item[0]}
          <!-- fill={item[0] === $filter5.discourse.page_id ? 'orange' : itemCount.has(item[0]) ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 0.15)'}> -->
          <!-- clip-path={`url(#clip-path-${i})`} -->
          <g transition:fade>
            <text y={15} fill="white" style="pointer-events: none;">
              {#each item.tokens as token}
                <tspan x={0} dy={14}>{token.trim()}</tspan>
              {/each}
            </text>
            <g
              on:click={() => {
                $selectedText = +$filteredLinks5.counter[0].page_id
                let tmp = $filter5
                tmp.counter.page_id = tmp.counter.page_id === item[0] ? undefined : item[0]
                $filter5 = tmp
              }}
              style="cursor: pointer;">
              <rect
                x={10 + Math.min(item.annotations[0].text.length * 0.003, 400)}
                width={60}
                height={22}
                stroke="orange"
                fill="orange"
                rx={3}
                ry={3}
                style="stroke-width: 0.5; fill-opacity: 0.1; stroke-opacity:
                0.5;" />
              <text
                dx={30}
                dy={1}
                fill="orange"
                style="text-anchor: middle; font-size: 12px;"
                x={10 + Math.min(item.annotations[0].text.length * 0.003, 400)}>
                {$_('read_the_text') + '...'}
              </text>
            </g>
          </g>
        {/if}
      </g>
    </g>
  {/each}
</g>
