<script>
  import { locale } from 'svelte-i18n'
</script>

<style>
  .switch {
    position: absolute;
    top: 40px;
    right: 20px;
  }

  button {
    background: none;
    outline: none;
    border: none;
    font-size: 14px;
    color: white;
    cursor: pointer;
    padding: 0 10px;
  }
</style>

<div class="switch">
  <button
    style="color: {$locale.startsWith('en') ? 'white' : 'rgba(255, 255, 255, 0.5)'};
    font-weight: {$locale.startsWith('en') ? 'bold' : 'normal'}"
    on:click={() => locale.set('en')}>
    English
  </button>
  <button
    style="color: {$locale.startsWith('de') ? 'white' : 'rgba(255, 255, 255, 0.5)'};
    font-weight: {$locale.startsWith('de') ? 'bold' : 'normal'}"
    on:click={() => locale.set('de')}>
    Deutsch
  </button>
</div>
