<script>
  import { locale } from 'svelte-i18n'
  import { fade } from 'svelte/transition'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()
</script>

<style>
  .outer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(1, 30, 44, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inner {
    border-radius: 5px;
    max-width: 640px;
    max-height: 80%;
    overflow: scroll;
    background-color: white;
    padding: 50px;
    position: relative;
  }

  h2,
  h3 {
    font-family: 'Catamaran', sans-serif;
  }

  .close {
    position: fixed;
    color: #00151e;
    font-size: 36px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: pointer;
  }

  .close-wrapper {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  img {
    max-width: 100%;
  }

  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }
  ol li {
    counter-increment: my-awesome-counter;
    display: flex;
    width: 50%;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
  ol li::before {
    content: counter(my-awesome-counter);
    font-weight: bold;
    font-size: 1rem;
    margin-right: 0.5rem;
    line-height: 1;
    background: #ffd68b;
    padding: 12px;
    border-radius: 30px;
    height: 8px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 13px;
    letter-spacing: 0;
    font-variant-numeric: tabular-nums;
  }

  .docs a {
    display: inline-block;
  }
</style>

<div class="outer" transition:fade on:click={() => dispatch('close')}>
  <div class="inner">
    <div class="close-wrapper">
      <div class="close" on:click={() => dispatch('close')}>
        <i class="far fa-times-circle" />
      </div>
    </div>
    {#if $locale.startsWith('de')}
      <h2>
        Ausgewählte wissenschaftliche Arbeiten aus dem Glasersfeld-Archiv
        visualisiert
      </h2>
      <p>
        Für die Visualisierung von argumentativen und diskursiven Strukturen
        wurden ausgewählte wissenschaftliche Arbeiten aus dem
        Ernst-von-Glasersfeld-Archiv, die zwischen 1960 und 2006 erschienen
        sind, digital aufbereitet und mit Zusatzinformationen versehen.
        Zusätzlich wurden eine Sammlung von Rezensionen aus der Zeitschrift
        "Ethik und Sozialwissenschaften 9 (1998)" sowie Glasersfelds Antworten
        auf die Kritiker in derselben Zeitschrift bearbeitet. Diese drei
        verschiedenen Texttypen - Glasersfeld-Texte, Kritiken anderer Autoren
        und Glasersfelds Antworten - bilden die Grundlage für die Annotation,
        Strukturierung und Visualisierung.
      </p>
      <p>
        Ernst von Glasersfeld veröffentlichte eine beträchtliche Anzahl an
        Aufsätzen und Büchern zur Theorie des radikalen Konstruktivismus. Seine
        wissenschaftlichen Argumente verbanden die Forschung, Theorien und
        Illustrationen anderer Menschen mit seinen eigenen Erfahrungen,
        Interpretationen und Wahrnehmungen im Alltag. Darüber hinaus verwendete
        Glasersfeld eine eigene Rhetorik, um seine Arbeit zu legitimieren und
        sein eigenes Tun zu erklären. Glasersfelds Arbeit hat definitiv den
        Diskurs darüber geprägt, wie die Welt wahrgenommen wird und wie Wissen
        entsteht. Zugleich haben seine Theorien zu einer Reihe von Kritiken und
        Gegenargumenten geführt, die in der Diskursgemeinschaft eine wichtige
        Rolle spielen. Denn die Diskurse werden von den Gemeinschaften ihrer
        Praktiker am Leben erhalten. Wissenschaftler*innen üben ihren eigenen
        Diskurs aus, indem sie sprechen, lesen, schreiben und veröffentlichen.
        Sie beschreiben nicht nur das, was sie erforschen, sondern sie
        konstruieren die diskursive Welt, in der wir uns bewegen.
      </p>
      <p>
        Das Ziel dieser Visualisierung ist es, sowohl die Gesamtstrukturen
        innerhalb eines bestimmten Textes als auch innerhalb der Textsammlung zu
        identifizieren und eine aussagekräftige visuelle Darstellung dieser
        Strukturen zu schaffen.
      </p>
      <h2>Wie funktioniert die Visualisierung?</h2>
      <h3>Alles steht miteinander in Beziehung</h3>
      <p>
        Bei näherer Betrachtung der Visualisierung kann festgestellt werden,
        dass alles miteinander in Beziehung steht. Wer sich zum Beispiel für ein
        bestimmtes Thema interessiert, kann schnell herausfinden, welche
        Glasersfeld-Texte Argumente enthalten, die mit diesem Thema in
        Zusammenhang stehen. Gleichzeitig ist erkennbar, welche Diskursarten in
        denselben Texten zu finden sind, ob es zu einem bestimmten Thema
        Gegenargumente anderer Autoren gibt und ob Glasersfeld Antworten auf
        diese spezifischen Gegenargumente geschrieben hat.
      </p>
      <img src="../img/1.png" alt="" />
      <img src="../img/2.png" alt="" />
      <p>
        Die Beziehungen zwischen Texten, Argumenten, Gegenargumenten und
        Glasersfelds Antworten finden auf verschiedenen Ebenen statt, was
        zunächst leicht verwirren kann. Um dieser Herausforderung gerecht zu
        werden, spiegelt die Visualisierung die Ordnung dieser verschiedenen
        Ebenen wider.
      </p>
      <ol>
        <li>
          Die oberste Ebene der Visualisierung stellt die Textebene dar. Die
          Balken spiegeln die Struktur der Texte wider. Die Breite der Balken
          repräsentiert die Länge und die Farben heben hervor, wo Anmerkungen
          hinzugefügt wurden.
        </li>
        <li>
          Jeder Glasersfeld-Text enthält spezifische Argumente, die einem oder
          mehreren Themen zugeordnet wurden. Wenn Glasersfeld also argumentiert,
          dass die Wirklichkeit konstruiert ist, wurde dieses Argument dem Thema
          ‘Realität’ zugeordnet.
        </li>
        <li>
          Auf der rechten Seite der Glasersfeld-Texte befinden sich die
          diskursiven Elemente, die in jeder wissenschaftlichen Arbeit zu finden
          sind. Diese Diskurse sind keinen Themen zugeordnet, da viele von ihnen
          themenunabhängig sind. Die Visualisierung legt jedoch offen, welche
          Diskurse gemeinsam mit bestimmten Argumentationen auftreten.
        </li>
        <li>
          Die Argumente von Glasersfeld stehen in Verbindung mit Gegenargumenten
          anderer Autoren. Wenn z. B. Michael Flacke Glasersfelds
          Argumentationen zum Thema Wissen kritisiert, so bezieht sich diese
          Kritik auf alle Argumente zum Thema ‘Wissen’.
        </li>
        <li>
          Glasersfeld selbst verfasste Antworten auf einige der Kritiken. Diese
          Antworten stehen in direktem Zusammenhang mit den Kritiken. Wenn
          Glasersfeld also auf Gegenargumente von Michael Flacke antwortet,
          steht diese Antwort in direktem Zusammenhang mit dieser Anmerkung.
        </li>
        <li>
          Personennamen, die innerhalb der Argumente erscheinen, werden am
          unteren Rand der Argumente angezeigt. Hier kann erkundet werden,
          welche Personen Glasersfeld in welchem Zusammenhang erwähnt hat.
        </li>
        <li>
          Die Texte anderer Autoren sind unterhalb der Gegenargumente
          visualisiert.
        </li>
      </ol>
      <img src="../img/3.png" alt="" />
      <h3>Die Argumente sind mehreren Themen zugeordnet</h3>
      <p>
        Die Themen, die den Glasersfeld-Argumenten zugeordnet wurden,
        repräsentieren im Wesentlichen die Theorie des Radikalen
        Konstruktivismus. Diese Themen sind sehr eng miteinander verwoben,
        weshalb einem Argument mehrere Themen zugeordnet werden können.
      </p>
      <img src="../img/4.png" alt="" />
      <p>
        Wenn ein bestimmtes Thema ausgewählt wird, z. B. ‘Erfahrung’, wird
        ersichtlich, welche weiteren Themen gleichzeitig mit diesem Thema
        auftreten (graue Farbe). Dies beeinflusst auch andere Ebenen in der
        Visualisierung: Wenn der einzige Filter das Thema 'Erfahrung' ist,
        besteht ein Verbindung zwischen 'Erfahrung' und dessen widersprüchlichen
        Gegenargumenten. Diese widersprüchlichen Gegenargumente sehen aber auch
        mit dem Thema ‘Wissen’ in Verbindung, ein Thema, das nicht ausgewählt
        wurde aber im gleichen Kontexten wie das Thema ‘Wissen’ vorkommt. Diese
        direkten und indirekten Beziehungen können zunächst etwas verwirren.
        Andererseits ermöglichen sie einen transparenten Einblick in die
        vielfältigen Verbindungen. Übrigens: jede Auswahl kann durch einen Klick
        auf ‘clear’ wieder rückgängig gemacht werden.
      </p>
      <h3>Entfaltung bis zum Originaltext</h3>
      <p>
        Jeder Glasersfeld-Text sowie die Texte anderer Autoren können innerhalb
        der Visualisierung erkundet werden, indem zunächst auf den Text und dann
        auf 'lesen' geklickt wird. Dadurch öffnet sich innerhalb der
        Visualisierung ein Fenster, in dem die Originaltexte samt hinzugefügten
        Annotationen angezeigt werden. Diese klare Verbindung zwischen
        Visualisierung und Originaltext ermöglicht Transparenz und
        Interpretierbarkeit. Die Visualisierung ist eine visuelle
        Suchmöglichkeit, die zu neuen und/oder verborgenen Stellen des Materials
        führen soll.
      </p>
      <img src="../img/5.png" alt="" />
      <h3>Einfachheit über Komplexität</h3>
      <p>
        Damit die Visualisierung nicht zu komplex wird, ist immer nur eine
        Auswahl pro Dimension möglich: So kann zum Beispiel nicht mehr als ein
        Thema oder mehr als Text gleichzeitig ausgewählt werden. Wäre diese
        Möglichkeit gegeben, müsste definiert werden, ob es sich um eine
        ODER-Kombination oder UND-Kombination handelt, z. B. ‘dieser
        Glasersfeld-Text ODER jener Glasersfeld-Text, ODER beide’, oder ‘dieser
        Glasersfeld-Text UND jener Glasersfeld-Text’. Wenn dies zuträfe, und
        zwar für alle Dimensionen, wäre es sehr schwierig, sinnvolle
        Informationen aus der Visualisierung zu erhalten.
      </p>
    {:else}
      <h2>Selected academic papers from the Glasersfeld Archive visualized</h2>
      <p>
        For the visualization of argumentation and discourse structures,
        selected academic papers from the Ernst-von-Glasersfeld-Archive, which
        were originally published between 1960 and 2006, a collection of
        critical reviews in the journal "Ethik und Sozialwissenschaften 9
        (1998)" [“Ethics and Social Sciences”] as well as Glasersfeld’s answers
        to the critics in the same journal were manually annotated. These three
        different types of texts - Glasersfeld texts, criticism from other
        authors, and Glasersfeld’s answers - form the basis for annotation,
        structuring and visualization.
      </p>
      <p>
        Over decades, Ernst von Glasersfeld published papers and books on the
        theory of Radical Constructivism. He created arguments by combining the
        research, theories and philosophies of other people with his own
        experiences, interpretations and perceptions in everyday life.
        Furthermore, he used his very own rhetoric to legitimize his work and
        explain his own approach. Glasersfeld’s work has definitely shaped the
        discourse on how the world is perceived and how knowledge is created. At
        the same time, his theories have led to a number of criticisms and
        counter-arguments which play an important role in the academic
        community. People, and especially scholars, practice their own discourse
        by talking, reading, writing and publishing. They do not only describe
        what they analyze, they construct the discursive world we are attending
        to.
      </p>
      <p>
        The goal of this visualization is both to identify the overall
        structures within a specific text as well within the collection of
        texts, and to create a meaningful visual representation of these
        structures.
      </p>
      <h2>How to read this visualization</h2>
      <h3>Everything is related to each other</h3>
      <p>
        When looking at the Glasersfeld visualization, you notice that
        everything is related to each other. For example, if you’re interested
        in a certain topic, you can easily find out which texts by Glasersfeld
        include arguments that are associated with that topic. At the same time,
        you can figure out which discourse types can be found in the same texts.
        Furthermore, you can learn if there are counter-arguments from other
        authors on a specific topic as well as if Glasersfeld wrote answers to
        these specific counter-arguments.
      </p>
      <img src="../img/1.png" alt="" />
      <img src="../img/2.png" alt="" />
      <p>
        The challenge, however, is that relationships do occur at different
        levels. To meet this challenge, the visualisation reflects the order of
        these different levels.
      </p>
      <ol>
        <li>
          The highest level of the visualisation is the text-layer. The bars
          reflect the structure of the texts. The width of the bars represent
          the length of the texts, the colors highlight where annotations have
          been added.
        </li>
        <li>
          Every Glasersfeld text contains specific arguments, which have been
          assigned to one or more topics. So when Glasersfeld argues that
          reality is constructed, this argument is assigned to the topic of
          ‘external reality’.
        </li>
        <li>
          On the left side of the Glasersfeld texts, you can find the discursive
          elements which can be found in every scientific paper. These
          discourses are not assigned to topics since many of them are topic
          independent. But you can find out which discourses appear in the same
          texts than specific arguments.
        </li>
        <li>
          The Glasersfeld arguments are connected to counter-arguments, which
          are made by other authors. When Michael Flacke, for example,
          criticises Glasersfeld arguments on the subject of knowledge, this
          refers to all arguments on ‘knowledge’.
        </li>
        <li>
          Glasersfeld itself wrote answers to some of the critiques. This answer
          are related directly to the specific critiques. So when Glasersfeld
          responds to Michael Flacke's criticism, this response is directly
          related to this text.
        </li>
        <li>
          Person names, which appear within the arguments, are shown on the
          bottom of the arguments. Here you can see which persons Glasersfeld
          mentioned in which context.
        </li>
        <li>
          The texts by other authors are visualized on the bottom of the
          counter-arguments.
        </li>
      </ol>
      <img src="../img/3.png" alt="" />
      <h3>Arguments are assigned to several topics</h3>
      <p>
        The topics assigned to the Glasersfeld arguments essentially represent
        his theory of Radical Constructivism. These topics are very interwoven,
        which means that one argument can be assigned to several topics.
      </p>
      <img src="../img/4.png" alt="" />
      <p>
        If you select a specific topic, e. g. ‘experience’, you can see which
        other topics appear within the same arguments (gray color). This also
        affects other relations: If your only filter is the topic ‘experience’’,
        you see a line between ‘experience’ and the counter-argument type
        ‘conflicting’. But from this ‘conflicting’ counter argument type you
        also see a line to the topic ‘knowledge’, which was not a topic you just
        selected but a topic that appears in the same context. These direct and
        indirect relationships can initially be somewhat confusing. On the other
        hand, they provide a transparent insight into the manifold connections.
        By the way: every selection can be undone by clicking on 'clear'.
      </p>
      <h3>The original text can be accessed through the visualization</h3>
      <p>
        Every Glasersfeld text as well as the texts by other authors can be
        accessed through the visualization by clicking first on the text and
        then on ‘read’. This will open up a window within the visualization,
        which shows the original texts with the annotations. This clear
        connection between visualization and original text allows transparency
        and interpretability. The visualization is a visual search option that
        should lead to new and/or hidden places of the material.
      </p>
      <img src="../img/5.png" alt="" />
      <h3>Simplification over complexity</h3>
      <p>
        One way to make sure that the visualization does not get too complex is
        that there is always only one selection per dimension possible: you
        cannot select more than one topic at the time, or more than one
        Glasersfeld text. (If that would be possible, you’d also have to reason
        if this is a selection where the combination is regarded as OR or as
        AND, for example, is it “this Glasersfeld text, OR that Glasersfeld
        text, OR both”, or is it “this Glasersfeld text AND that Glasersfeld
        text”. If this were true, and also true for all the dimensions it would
        be very hard to understand what your selection actually means.
      </p>
    {/if}

    <div class="docs">
      <a href="https://zenodo.org/record/4041492" target="_blank">
        <i class="fas fa-external-link-alt" />
        Read about the annotation guidelines
      </a>
      <a href=" https://zenodo.org/record/4067036#.X3tiWefgpPY" target="_blank">
        <i class="fas fa-external-link-alt" />
        Read more about the visualization and the theoretical concept behind it
      </a>
    </div>

  </div>
</div>
