<script>
  import { setContext } from 'svelte'
  import { _ } from 'svelte-i18n'
  import ClearFilter from './ClearFilter.svelte'

  export let x = 0
  export let y = 0
  export let dy = -30
  export let dx = 70
  export let title
  export let textAnchor = 'middle'
  export let width = 140
  export let fontSize = 14
  export let fontStyle = 'normal'
  export let fill = 'white'
  export let group
  export let prop

  setContext('width', width)
</script>

<g transform="translate({x}, {y})">
  {#if title}
    <text
      {dx}
      {dy}
      class="title"
      style="text-anchor: {textAnchor}; font-size: {fontSize}; font-style: {fontStyle};
      fill: {fill}">
      <tspan>{$_(title)}</tspan>
      <ClearFilter {group} {prop} />
    </text>
  {/if}
  <g transform="translate(0, 0)">
    <slot />
  </g>
</g>
