import { readable, derived, writable } from 'svelte/store'
import { csv, text, dsv } from 'd3-fetch'
import { autoType } from 'd3-dsv'
import { group, ascending } from 'd3-array'
import { addMessages, init, getLocaleFromNavigator } from 'svelte-i18n'
import { hierarchy, tree } from 'd3-hierarchy'
import en from './i18n/en.json'
import de from './i18n/de.json'

addMessages('en', en)
addMessages('en-US', en)
addMessages('de', de)

export const selectedDomain = writable(undefined)
export const selectedAuthor = writable(undefined)
export const selectedLanguage = writable(undefined)
export const selectedPublicationType = writable(undefined)
export const selectedTitle = writable(undefined)


export const metadata = readable([], set => {
  csv('data/metadata_extract.csv').then(data => set(data.map(autoType)))
})

init({
  fallbackLocale: 'de',
  initialLocale: getLocaleFromNavigator(),
})

const argumentationTypes = ['Beispiel', 'Prämisse', 'Schlussfolgerung']

export const loginAllowed = writable(false)

const monthMap = {
  January: '01',
  February: '02',
  March: '03',
  April: '04',
  May: '05',
  June: '06',
  July: '07',
  August: '08',
  September: '09',
  October: '10',
  November: '11',
  December: '12',
}

export const library = readable(undefined, set => {
  dsv(';', 'data/library.csv').then(data => {
    set(
      data.reduce((acc, cur) => {
        if (cur.author_editor.indexOf('/') > -1) {
          cur.author_editor.split(' / ').forEach(d =>
            acc.push({
              ...cur,
              author_editor: d,
            })
          )
        } else {
          acc.push(cur)
        }

        return acc
      }, []).sort((a, b) => a.domain.localeCompare(b.domain))
    )
  })
})

export const fullCollection = readable(undefined, set => {
  // text('data/all.csv').then((data) => {
  text('data/all_with_enriched_data.csv').then(data => {
    const rows = data.split('\n')
    let columns = rows[0].split('@')

    let result = []

    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].split('@')

      result.push(
        columns.reduce((acc, cur, j) => {
          acc[cur] = row[j]
          return acc
        }, {})
      )
    }

    result = result.map(d => {
      return {
        ...d,
        year: +d.year,
        month: d.month,
        timestamp: +`${d.year}${monthMap[d.month]}`,
      }
    })
    set(
      tree()
        .size([360, 500])
        .separation((a, b) => (a.parent == b.parent ? 1 : 3) / a.depth)(
        hierarchy(
          result
            // .filter(d => ['lifedocuments'].includes(d.section))
            .reduce(
              (acc, cur, i) => {
                let section = acc.children.find(d => d.name === cur.section)
                if (!section) {
                  section = {
                    name: cur.section,
                    section: cur.section,
                    timestamp: cur.timestamp,
                    source: cur.source,
                    index: i,
                    children: [],
                  }
                  acc.children.push(section)
                }

                let cassette = section.children.find(
                  d => d.name === cur.cassette
                )
                if (!cassette) {
                  cassette = {
                    name: cur.cassette,
                    section: cur.section,
                    timestamp: cur.timestamp,
                    source: cur.source,
                    index: i,
                    children: [],
                  }
                  section.children.push(cassette)
                }

                cassette.children.push({
                  ...cur,
                  name: `${cur.section}_${cur.cassette}_${cur.folder}`,
                  index: i,
                })

                return acc
              },
              { name: 'bestand', section: 'bestand', children: [], index: 0 }
            )
        )
          .count()
          .sort((a, b) => {
            return
            ascending(a.data.name, b.data.name)
          })
      )
    )
  })
})

export const annotations2 = derived(
  metadata,
  ($metadata, set) => {
    if (!$metadata || $metadata.length === 0) {
      return []
    }

    csv('data/annotation_extract.csv').then(data => {
      const result = data
        .map(autoType)
        .map(d => ({
          ...d,
          category: d.category.replace('2', '').replace('3', ''),
        }))
        .sort((a, b) => a.offsetBegin - b.offsetBegin)

      result.forEach(d => {
        if (argumentationTypes.includes(d.category)) {
          const parent = result.find(
            a =>
              a.page_id === d.page_id &&
              (a.category === 'Argumentation' ||
                a.category === 'ArgumentationFremd') &&
              d.offsetBegin >= a.offsetBegin &&
              d.offsetEnd <= a.offsetEnd
          )

          if (parent) {
            if (!parent.children) {
              parent.children = []
            }

            parent.children.push(d)
            d.parent = parent
          }
        }

        const text = $metadata.find(m => m.page_id === d.page_id)

        d.text = text

        d.author = text ? text.author : undefined

        d.title = text ? text.title : undefined
        d.title =
          d.author !== 'Ernst von Glasersfeld' && d.title
            ? d.title
                .replace('–', '|')
                .replace('-', '|')
                .split('|')[1]
            : d.title

        d.page_id_str = String(d.page_id)

        d.persons =
          d.persons === null ? [] : d.persons.split(';').filter(d => d !== '')

        d.locs = d.locs === null ? [] : d.locs.split(';').filter(d => d !== '')

        d.topics =
          d.topics === null ? [] : d.topics.split(';').filter(d => d !== '')

        d.innovation_types =
          d.innovation_type === null
            ? []
            : d.innovation_type.split(';').filter(d => d !== '')
        d.innovation_type =
          d.innovation_type !== null ? d.innovation_type.replace(';', '') : null

        d.narrative_types =
          d.narrative_type === null
            ? []
            : d.narrative_type.split(';').filter(d => d !== '')

        d.reference_types =
          d.reference_type === null
            ? []
            : d.reference_type.split(';').filter(d => d !== '')

        if (d.relationen_argumentation_fremd) {
          d.counterArgumentType =
            d.relationen_argumentation_fremd === null
              ? undefined
              : [
                  ...new Set(
                    d.relationen_argumentation_fremd
                      .split(';')
                      .map(d => d.split('||')[1])
                  ),
                ][0]
        }

        if (d.relationen_antwort_glasersfeld) {
          d.glasersfeldAnswerType =
            d.relationen_antwort_glasersfeld === null
              ? undefined
              : [
                  ...new Set(
                    d.relationen_antwort_glasersfeld.split('|').reverse()
                  ),
                ][0]

          d.relatedCounterArgument =
            d.relationen_antwort_glasersfeld === null
              ? undefined
              : [
                  ...new Set(
                    d.relationen_antwort_glasersfeld
                      .split('//')
                      .reverse()[0]
                      .split('|')[0]
                      .split('@')
                  ),
                ][0]
          d.relatedCounterArgument =
            d.relatedCounterArgument === ''
              ? undefined
              : d.relatedCounterArgument
        }
      })

      result
        .filter(d => d.relatedCounterArgument)
        .forEach(d => {
          if (d.relatedCounterArgument) {
            d.relatedCounterArgumentText = result.find(
              a => a.annotation_id === d.relatedCounterArgument
            )

            if (d.relatedCounterArgumentText.text) {
              d.relatedCounterArgumentText.text.title
              d.topics = d.relatedCounterArgumentText.topics
            }

            d.relatedCounterArgumentText.glasersfeldAnswerType =
              d.glasersfeldAnswerType
            // d.topics = d.relatedCounterArgumentText.topics
            // d.counterArgumentType =
            // d.relatedCounterArgumentText.counterArgumentType
          }
        })

      set(result)
    })
  },
  []
)

export const glasersfeldTexts = derived(annotations2, $annotations2 =>
  $annotations2.filter(
    d => d.text && d.text.author.indexOf('Ernst von Glasersfeld') > -1
  )
)

export const nonGlasersfeldTexts = derived(annotations2, $annotations2 =>
  $annotations2.filter(
    d => d.text && d.text.author.indexOf('Ernst von Glasersfeld') === -1
  )
)

export const selectedTopic = writable(undefined)
export const selectedText = writable(undefined)
export const textHeight = writable(undefined)
export const textElement = writable(undefined)
export const annotationElements = writable(undefined)
export const selectedTextCollection = writable('glasersfeld')
export const scrollTop = writable(0)

export const topicList = derived(annotations2, $annotations2 =>
  [
    ...new Set(
      $annotations2.reduce((acc, cur) => acc.concat(cur.topics), []).flat()
    ),
  ].sort((a, b) => a.localeCompare(b))
)

export const selectedTextIds = derived(
  [selectedTopic, annotations2],
  ([$selectedTopic, $annotations2]) => [
    ...new Set(
      $annotations2
        .filter(d =>
          !$selectedTopic ? true : d.topics.includes($selectedTopic)
        )
        .map(d => d.page_id)
    ),
  ],
  []
)

export const filters = writable({})

export const filteredAnnotations = derived(
  [annotations2, filters],
  ([$annotations2, $filters]) => {
    return Object.keys($filters).length === 0 && $filters.constructor === Object
      ? $annotations2
      : $annotations2.filter(d =>
          Object.values($filters).reduce((acc, cur) => acc && cur(d), true)
        )
  }
)

export const links2 = derived(annotations2, $annotations2 => {
  const filteredData = {
    counter: [],
    argumentations: [],
    discourses: [],
    persons: [],
  }

  $annotations2
    .map(d => ({
      ...d,
      sort_index: d.category === 'AntwortGlasersfeld' ? 1 : 0,
    }))
    .sort((a, b) => a.sort_index - b.sort_index)
    .forEach(d => {
      if (
        [
          'Argumentation',
          'Innovationsdiskurs',
          'WissenschaftlicheReferenz',
          'Narrativ',
        ].includes(d.category)
      ) {
        if (d.category === 'Argumentation') {
          filteredData.argumentations.push({
            page_id: d.page_id_str,
            annotation_id: d.annotation_id,
            topics: d.topics,
            persons: d.persons,
          })
        } else if (d.category === 'Innovationsdiskurs') {
          filteredData.discourses.push({
            page_id: d.page_id_str,
            title: d.text.title,
            annotation_id: d.annotation_id,
            type: d.innovation_types[0],
            category: 'innovation',
          })
        } else if (d.category === 'WissenschaftlicheReferenz') {
          filteredData.discourses.push({
            page_id: d.page_id_str,
            title: d.text.title,
            annotation_id: d.annotation_id,
            type: d.reference_types[0],
            category: 'reference',
          })
        } else if (d.category === 'Narrativ') {
          filteredData.discourses.push({
            page_id: d.page_id_str,
            title: d.text.title,
            annotation_id: d.annotation_id,
            type: d.narrative_types[0],
            category: 'narrative',
          })
        }
      } else if (
        ['ArgumentationFremd', 'AntwortGlasersfeld'].includes(d.category)
      ) {
        if (d.category === 'AntwortGlasersfeld') {
          filteredData.counter.push({
            page_id: d.relatedCounterArgumentText.page_id_str,
            title: d.relatedCounterArgumentText.text.title || 'MISSING TITLE',
            counter_argument: d.relatedCounterArgumentText.counterArgumentType,
            topics: d.relatedCounterArgumentText.topics,
            answer: d.glasersfeldAnswerType,
          })
        } else if (d.category === 'ArgumentationFremd') {
          filteredData.counter.push({
            page_id: d.page_id_str,
            title: d.text.title || 'MISSING TITLE',
            counter_argument: d.counterArgumentType,
            topics: d.topics,
            answer: undefined,
          })
        }
      }
    })

  return {
    counter: filteredData.counter,
    argumentations: filteredData.argumentations,
    discourses: filteredData.discourses,
    persons: filteredData.persons,
  }
})

export const filter5 = writable({
  argumentations: {
    topic: undefined,
    person: undefined,
  },
  discourse: {
    page_id: undefined,
    narrative: undefined,
    innovation: undefined,
    reference: undefined,
  },
  counter: {
    page_id: undefined,
    counter_argument: undefined,
    answer: undefined,
  },
})

export const filteredLinks5 = derived(
  [links2, filter5],
  ([$links2, $filter5]) => {
    let counter = $links2.counter
    let argumentations = $links2.argumentations
    let discourses = $links2.discourses
    let persons = $links2.argumentations.map(d => d.persons).flat()

    const counterFilter = Object.entries($filter5.counter).filter(d => d[1])
    let filteredCounter = counter.filter(d =>
      counterFilter.map(f => d[f[0]] === f[1]).every(d => d === true)
    )

    let filteredNarrative = discourses.filter(
      d =>
        d.category === 'narrative' &&
        ($filter5.discourse.narrative
          ? d.type === $filter5.discourse.narrative
          : true)
    )

    let filteredReference = discourses.filter(
      d =>
        d.category === 'reference' &&
        ($filter5.discourse.reference
          ? d.type === $filter5.discourse.reference
          : true)
    )
    let filteredInnovation = discourses.filter(
      d =>
        d.category === 'innovation' &&
        ($filter5.discourse.innovation
          ? d.type === $filter5.discourse.innovation
          : true)
    )

    let filteredArgumentation = argumentations.filter(
      d =>
        ($filter5.argumentations.topic
          ? d.topics.includes($filter5.argumentations.topic)
          : true) &&
        ($filter5.argumentations.person
          ? d.persons.includes($filter5.argumentations.person)
          : true)
    )
    // TODO: this is not filtered by person filter!!!
    let pageIdIntersection = $filter5.discourse.page_id
      ? [$filter5.discourse.page_id]
      : argumentations
          .filter(d =>
            d.topics
              .map(t =>
                filteredCounter
                  .map(d => d.topics)
                  .flat()
                  .includes(t)
              )
              .some(d => d === true)
          )
          .concat(counterFilter.length === 0 ? discourses : [])
          .map(d => d.page_id)

    if ($filter5.argumentations.person) {
      pageIdIntersection = pageIdIntersection.filter(d =>
        filteredArgumentation
          .filter(d => d.persons.includes($filter5.argumentations.person))
          .map(d => d.page_id)
          .includes(d)
      )
    }

    if ($filter5.discourse.innovation) {
      pageIdIntersection = pageIdIntersection.filter(d =>
        filteredInnovation.map(d => d.page_id).includes(d)
      )
    }

    if ($filter5.discourse.narrative) {
      pageIdIntersection = pageIdIntersection.filter(d =>
        filteredNarrative.map(d => d.page_id).includes(d)
      )
    }

    if ($filter5.discourse.reference) {
      pageIdIntersection = pageIdIntersection.filter(d =>
        filteredReference.map(d => d.page_id).includes(d)
      )
    }

    if ($filter5.argumentations.topic) {
      pageIdIntersection = pageIdIntersection.filter(d =>
        filteredArgumentation.map(d => d.page_id).includes(d)
      )
    }

    pageIdIntersection = Array.from(new Set(pageIdIntersection))

    let topicIntersection = $filter5.argumentations.topic
      ? [$filter5.argumentations.topic]
      : argumentations
          .filter(d =>
            $filter5.argumentations.person
              ? d.persons.includes($filter5.argumentations.person)
              : true
          )
          .filter(d => pageIdIntersection.includes(d.page_id))
          .map(d => d.topics)
          .flat()

    if (counterFilter.length > 0) {
      topicIntersection = topicIntersection.filter(d =>
        filteredCounter
          .map(d => d.topics)
          .flat()
          .includes(d)
      )
    }

    topicIntersection = Array.from(new Set(topicIntersection))

    const argumentationsResult = filteredArgumentation.filter(
      d =>
        pageIdIntersection.includes(d.page_id) &&
        d.topics.map(t => topicIntersection.includes(t)).some(d => d === true)
    )

    return {
      counter: filteredCounter.filter(d =>
        d.topics.map(t => topicIntersection.includes(t)).some(d => d === true)
      ),
      argumentations: argumentationsResult,
      discourses: filteredNarrative
        .concat(filteredReference)
        .concat(filteredInnovation)
        .filter(d => pageIdIntersection.includes(d.page_id)),
      persons: Array.from(
        group(argumentationsResult.map(d => d.persons).flat(), d => d)
      ).sort((a, b) => b[1].length - a[1].length),
    }
  }
)

export const allTopics = derived(links2, $links2 =>
  Array.from(
    group($links2.argumentations.map(d => d.topics).flat(), d => d)
  ).sort((a, b) => a[0].localeCompare(b[0]))
)

export const allCounterArguments = derived(links2, $links2 =>
  Array.from(group($links2.counter, d => d.counter_argument)).sort((a, b) =>
    a[0].localeCompare(b[0])
  )
)

export const allAnswers = derived(links2, $links2 =>
  Array.from(
    group(
      $links2.counter.filter(d => d.answer),
      d => d.answer
    )
  ).sort((a, b) => a[0].localeCompare[b[0]])
)

export const filteredConnections = derived(
  [filteredLinks5, links2],
  ([$filteredLinks5, $links2]) => {
    const counter_answer_count = group(
      $filteredLinks5.counter.filter(d => d.answer),
      d => [d.counter_argument, d.answer].join('_')
    )

    const counter_answer = Array.from(
      group(
        $links2.counter.filter(d => d.answer),
        d => [d.counter_argument, d.answer].join('_')
      )
    ).map(d => [
      d[0].split('_')[0],
      d[0].split('_')[1],
      counter_answer_count.has(d[0])
        ? counter_answer_count.get(d[0]).length
        : 0,
    ])

    const topics_counter_count = $filteredLinks5.counter.reduce((acc, cur) => {
      cur.topics.forEach(t => {
        const key = [t, cur.counter_argument].join('_')
        if (!acc[key]) {
          acc[key] = 1
        } else {
          acc[key]++
        }
      })
      return acc
    }, {})

    const topics_counter = Object.entries(
      $links2.counter.reduce((acc, cur) => {
        cur.topics.forEach(t => {
          const key = [t, cur.counter_argument].join('_')
          if (!acc[key]) {
            acc[key] = 1
          } else {
            acc[key]++
          }
        })
        return acc
      }, {})
    ).map(d => [
      d[0].split('_')[0],
      d[0].split('_')[1],
      topics_counter_count[d[0]] || 0,
    ])

    return {
      counter_answer,
      topics_counter,
    }
  }
)
