<script>
  import { curveMonotoneX, line } from 'd3-shape'
  import { spring } from 'svelte/motion'

  export let fromX
  export let fromY
  export let toX
  export let toY
  export let width

  $: strokeWidth = spring(0, { stiffness: 0.1 })

  $: {
    $strokeWidth = width
  }

  const l = line()
    .x((d) => d[0])
    .y((d) => d[1])
    .curve(curveMonotoneX)
</script>

<path
  fill="none"
  stroke="white"
  style="stroke-width: {$strokeWidth};"
  opacity={0.8}
  d={l([[fromX, fromY], [fromX + 1, fromY], [toX - 1, toY], [toX, toY]])} />
