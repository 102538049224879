<script>
  import {
    annotations2,
    filter5,
    links2,
    filteredLinks5,
    filteredConnections,
    allTopics,
    allCounterArguments,
    allAnswers,
  } from './stores2.js'
  import GlasersfeldTextFilter from './GlasersfeldTextFilter.svelte'
  import DiscourseFilter from './DiscourseFilter.svelte'
  import TopicsFilter from './TopicsFilter.svelte'
  import CounterArgumentFilter from './CounterArgumentFilter.svelte'
  import AnswerFilter from './AnswerFilter.svelte'
  import NonGlasersfeldTextFilter from './NonGlasersfeldTextFilter.svelte'
  import Filter from './Filter.svelte'
  import Connections from './Connections.svelte'
  import { _ } from 'svelte-i18n'
  import Persons from './PersonFilter.svelte'

  export let margin
</script>

<style>
  #sequence {
    height: 1550px;
  }
</style>

{#if $annotations2 && $annotations2.length > 0 && $annotations2[0].text}
  <div id="sequence">
    <svg width={1200} height={2000}>
      <g transform="translate({margin.left}, {margin.top})">
        <g transform="translate(0, 0)">
          <Filter
            x={490}
            y={560}
            dy={-20}
            dx={0}
            title="persons"
            textAnchor="start"
            group="argumentations"
            prop="person">
            <Persons />
          </Filter>
          <Filter
            x={170}
            dx={300}
            title="text_by_glasersfeld"
            textAnchor="end"
            group="discourse"
            prop="page_id">
            <GlasersfeldTextFilter />
          </Filter>
          <Filter
            x={490}
            title="argumentations"
            group="argumentations"
            prop="topic">
            <TopicsFilter />
          </Filter>
          <Filter
            x={760}
            title="counter_arguments"
            group="counter"
            prop="counter_argument">
            <CounterArgumentFilter />
          </Filter>
          <Filter
            x={760}
            y={250}
            dy={-20}
            dx={0}
            title="text_by_other_author"
            textAnchor="start"
            group="counter"
            prop="page_id">
            <NonGlasersfeldTextFilter />
          </Filter>
          <Filter
            x={1020}
            title="glasersfeld_answers"
            group="counter"
            prop="answer">
            <AnswerFilter />
          </Filter>
          <Connections
            connections={$filteredConnections.topics_counter}
            fromList={$allTopics}
            toList={$allCounterArguments}
            fromX={632}
            toX={758} />
          <Connections
            connections={$filteredConnections.counter_answer}
            fromList={$allCounterArguments}
            toList={$allAnswers}
            fromX={902}
            toX={1018} />
        </g>
        <g transform="translate(0, 0)">
          <text
            y={0}
            dy={-30}
            dx={70}
            style="text-anchor: middle; font-size: 14px"
            fill="white">
            {$_('discursive_levels')}
          </text>
          <Filter
            x={0}
            y={0}
            dy={-10}
            title="scientific_reference"
            fill="rgba(255, 255, 255, 0.5)"
            width={140}
            fontSize={12}
            fontStyle="italic"
            group="discourse"
            prop="reference">
            <DiscourseFilter category="reference" />
          </Filter>
          <Filter
            x={0}
            y={355}
            dy={-10}
            title="narrative"
            fill="rgba(255, 255, 255, 0.5)"
            width={140}
            fontSize={12}
            fontStyle="italic"
            group="discourse"
            prop="narrative">
            <DiscourseFilter category="narrative" />
          </Filter>
          <Filter
            x={0}
            y={455}
            dy={-10}
            title="innovation_discourse"
            fill="rgba(255, 255, 255, 0.5)"
            width={140}
            fontSize={12}
            fontStyle="italic"
            group="discourse"
            prop="innovation">
            <DiscourseFilter category="innovation" charsForHeight={true} />
          </Filter>
        </g>
      </g>
    </svg>
  </div>
{/if}
