<script>
  import { filteredConnections } from './stores2.js'
  import { stacked2 } from './common'
  import TweenedConnection from './TweenedConnection.svelte'

  export let connections
  export let fromList
  export let toList
  export let fromX
  export let toX

  const stackedFrom = stacked2(fromList)
  const stackedTo = stacked2(toList)
</script>

{#each connections as connection}
  {#if stackedFrom[connection[0]] && stackedTo[connection[1]]}
    <TweenedConnection
      {fromX}
      fromY={stackedFrom[connection[0]].cy}
      {toX}
      toY={stackedTo[connection[1]].cy}
      width={connection[2] * 0.6} />
  {/if}
{/each}
