import { text } from 'd3-fetch'
import { request } from 'd3-request'

const NAMESPACE_PREFIX = 'VisualizationComment:'
const URL_API_BASE =
  document.location.protocol + '//dbis-digivis.uibk.ac.at/mediawiki/api.php'
const ORIGIN = '&ORIGIN=*'

export async function queryAllVisualizationComments () {
  $('#comments').empty()

  // object to collect all comments
  let comments = {}

  let run = true
  let offset = 0

  let askParams = 'action=ask&format=json'
  let askQuery = encodeURIComponent(
    '[[-Has subobject::<q>[[VisualizationComment:+]]</q>]]|?textTitle|?annotationId|?user|?timestamp|?comment'
  )

  // In case number of comments is greather than the API limit, loop until all comments are fetched from API
  while (run) {
    let urlRequest = `${URL_API_BASE}?${askParams}${ORIGIN}&query=${askQuery}|offset=${offset}`
    let response = await fetch(urlRequest)
    let json = await response.json()

    try {
      let results = json.query.results
      for (let key of Object.keys(results)) {
        let printouts = results[key].printouts
        console.log(printouts['AnnotationId'][0])
        // collect current comment with metadata in object
        comments[key] = {
          annotationId: printouts['AnnotationId'][0],
          comment: printouts['Comment'][0],
          textTitle: printouts['TextTitle'][0],
          timestamp: printouts['Timestamp'][0]['timestamp'],
          timestamp_raw: printouts['Timestamp'][0]['raw'],
          user: printouts['User'][0],
        }
        $('#comments').append(
          `<p><b>${key}:</b></b><span>${JSON.stringify(
            comments[key]
          )}</span></p>`
        )
      }

      // this holds true if not all comments were retrieved due to API limits
      if (json.hasOwnProperty('query-continue-offset')) {
        offset = json.hasOwnProperty('query-continue-offset')
          ? json['query-continue-offset']
          : false
      } else {
        offset = false
      }

      // exit loop after last batch of walk-names retrieved
      run = offset !== false
    } catch (e) {
      console.log('Error processing results fetching comments from API', e)
      run = false
    }
  }
  return comments
}

export function isUserLoggedOn () {
  // console.log(`${URL_API_BASE}?format=json&action=query&meta=userinfo${ORIGIN}`)
  // return fetch(
  //   `${URL_API_BASE}?format=json&action=query&meta=userinfo${ORIGIN}`,
  //   { mode: 'no-cors' }
  // )

  $.ajax({
    url: `${URL_API_BASE}?format=json&action=query&meta=userinfo${ORIGIN}`,
    method: 'GET',
    crossDomain: true,
    headers: {
      'Access-Control-Allow-Headers': 'x-requested-with',
    },
    success: function (response) {
      console.log(respons)
    },
    error: function (headers, textStatus, error) {
      // most probably the server is down or some other problem with MediaWiki at the server
      console.log(textStatus, error)
    },
  })
}

export function submitComment () {
  let comment = $('#commentField').val()

  // get userInfo from API
  $.ajax({
    url: `${URL_API_BASE}?format=json&action=query&meta=userinfo${ORIGIN}`,
    method: 'GET',
    success: function (response) {
      let userId = response.query.userinfo.id
      let userName = response.query.userinfo.name

      if (userId === 0) {
        // user not logged in, show login page in overlay
        $('#loginOverlay').show()
      } else {
        // user logged in
        preprocessComment(
          'WriteCommentToMediaWiki',
          'annotationId',
          userName,
          comment
        )
      }
    },
    error: function (headers, textStatus, error) {
      // most probably the server is down or some other problem with MediaWiki at the server
      console.log(textStatus, error)
    },
  })
}

function preprocessComment (textTitle, annotationId, user, comment) {
  // build string to save the informations as SemanticMediaWiki subobject
  const timestamp = new Date().toJSON()
  const subobject =
    '\n\n{{#subobject:\n' +
    `| textTitle=${textTitle}\n` +
    `| annotationId=${annotationId}\n` +
    `| user=${user}\n` +
    `| timestamp=${timestamp}\n` +
    `| comment=${comment}\n` +
    '}}'
  appendSMWSubobjectToMediaWikiPage(
    textTitle,
    annotationId,
    user,
    timestamp,
    subobject
  )
}

function appendSMWSubobjectToMediaWikiPage (
  textTitle,
  annotationId,
  user,
  timestamp,
  subobject
) {
  // create unique name for MediaWiki page
  let pageName = `${NAMESPACE_PREFIX}${textTitle}-${annotationId}-${user}-${timestamp}`

  // get edit-token for user from MediaWiki API
  $.ajax({
    url: URL_API_BASE + '?action=query&meta=tokens&format=json',
    dataType: 'json',
    success: function (data) {
      editToken = data.query.tokens.csrftoken

      // Send edit request to MediaWiki API (appending text to )
      $.post(
        URL_API_BASE,
        {
          format: 'json',
          action: 'edit',
          title: pageName,
          appendtext: subobject,
          token: editToken,
        },
        function (response) {
          if (response.hasOwnProperty('edit')) {
            if (response.edit.hasOwnProperty('result')) {
              if (response.edit.result === 'success') {
                // write was successful
              } else {
                console.log(
                  'Editing MediaWiki-Page finished in unknown state',
                  response
                )
              }
            } else if (response.hasOwnProperty('error')) {
              // most probably some problem with MediaWiki at the server
              console.log('Error with editing MediaWiki-Page', response)
            }
          }
        }
      )
    },
    error: function (headers, textStatus, error) {
      console.log(
        `Error on getting edit token for user ${user}.`,
        textStatus,
        error
      )
    },
  })
}
