<script>
  import {
    textHeight,
    glasersfeldTexts,
    nonGlasersfeldTexts,
    selectedText,
    annotationElements,
    selectedTextCollection,
    annotations2,
    scrollTop,
    topicList,
  } from './stores2.js'
  import { scaleLinear } from 'd3-scale'
  import { color } from 'd3-color'
  import { group } from 'd3-array'
  import { getContext } from 'svelte'
  import { line, curveCatmullRom } from 'd3-shape'
  import { _ } from 'svelte-i18n'

  $: annotations = $annotations2.filter((d) => d.page_id === $selectedText)

  const categories = [
    'Argumentation',
    'WissenschaftlicheReferenz',
    'Narrativ',
    'Innovationsdiskurs',
    'Prämisse',
    'Beispiel',
    'Schlussfolgerung',
  ]

  const c = getContext('c')

  $: y = scaleLinear()
    .domain([
      0,
      annotations && annotations.length > 0 ? annotations[0].text.length : 0,
    ])
    .range([0, $textHeight])

  const colors = {
    beispiel: 'lightgreen',
    pramisse: 'pink',
    schulssfolgerung: 'steelblue',
    wissenschaftlichereferenz: 'yellow',
    narrativ: 'orange',
    innovationsdiskurs: 'brown',
  }

  $: byTopic = Array.from(
    group(
      $annotationElements
        .filter((d) =>
          [
            'Beispiel',
            'Prämisse',
            'Schlussfolgerung',
            'ArgumentationFremd',
          ].includes(d.annotation.category)
        )
        .reduce((acc, cur) => {
          if (cur.annotation.parent) {
            cur.annotation.parent.topics.forEach((topic) =>
              acc.push({
                topic,
                ...cur,
              })
            )
          } else if (cur.annotation.category === 'ArgumentationFremd') {
            cur.annotation.topics.forEach((topic) =>
              acc.push({
                topic,
                ...cur,
              })
            )
          } else {
            console.error('ERROR: no parent defined for Annotation', cur)
          }

          return acc
        }, [])
        .sort((a, b) => a.offsetTop - b.offsetTop),
      (d) => d.topic
    )
  )

  const l = line()
    .x((d) => d[0])
    .y((d) => d[1])
    .curve(curveCatmullRom)

  $: toPath = (topic) => {
    const x =
      (c.domain().indexOf(topic[0]) * innerWidth) / c.domain().length +
      (0.5 * innerWidth) / c.domain().length

    const points = topic[1].reduce(
      (acc, cur, i) => {
        acc.push([
          i % 2 === 0 ? x - 17 : x + 17,
          (cur.offsetTop + 0.5 * cur.offsetHeight + acc[i * 2][1]) / 2,
        ])
        acc.push([x, cur.offsetTop + 0.5 * cur.offsetHeight])

        return acc
      },
      [[x, 0]]
    )

    points.push([
      x + (points.length % 2) === 0 ? -17 : 17,
      (points[points.length - 1][1] + $textHeight) / 2,
    ])
    points.push([x, $textHeight])

    return l(points)
  }

  const f = (category) =>
    color(
      category === 'Beispiel'
        ? '#d5efc7'
        : category === 'Prämisse'
        ? '#b7d2e7'
        : category === 'ArgumentationFremd'
        ? '#f7dede'
        : '#ffe952'
    )

  const width = 200
  const margin = {
    top: 0,
    right: 20,
    bottom: 0,
    left: 20,
  }
  const innerWidth = width - margin.left - margin.right
</script>

<style>
  text {
    text-transform: uppercase;
    font-size: 0.6rem;
    letter-spacing: 0.2rem;
    text-anchor: middle;
  }

  path {
    stroke-width: 5;
    opacity: 0.6;
    /* stroke-dasharray: 1 1; */
    fill: none;
  }

  .text-back {
    stroke-width: 8;
    opacity: 0.8;
  }

  circle {
    fill: #00354e;
  }

  .circle-front {
    stroke-width: 3;
  }
</style>

<svg {width} height={$textHeight}>

  {#each $annotationElements.filter((d) =>
    d.annotation.category.startsWith('Argumentation')
  ) as element}
    <rect
      y={element.offsetTop}
      {width}
      height={element.offsetHeight}
      style="fill: {element.annotation.category === 'Argumentation' ? '#deeff7' : '#f7dede'};" />
  {/each}

  <g transform="translate({margin.left}, {margin.top})">
    {#each $annotationElements.filter((d) =>
      [
        'Beispiel',
        'Prämisse',
        'Schlussfolgerung',
        'ArgumentationFremd',
      ].includes(d.annotation.category)
    ) as element}
      {#if element.annotation.parent}
        {#each element.annotation.parent.topics as topic}
          <g transform="translate(0, {element.offsetTop})">
            <rect
              width={innerWidth}
              height={element.offsetHeight}
              style="fill: {f(element.annotation.category)};" />
          </g>
        {/each}
      {:else}
        {console.log('ERROR: no parent defined for Annotation', element)}
      {/if}
    {/each}

    {#each byTopic as topic}
      <path
        d={toPath(topic)}
        id="{topic[0]}-path"
        style="stroke: {c(topic[0])}" />

      {#each Array(1).fill(topic[0]) as label, i}
        <text style="text-anchor: start;" dy={-3}>
          <textPath
            href="#{topic[0]}-path"
            startOffset={120 + $scrollTop}
            style="fill: {c(topic[0])}">
            {$_(label)}
          </textPath>
        </text>
      {/each}
    {/each}

    {#each $annotationElements.filter((d) =>
      [
        'Beispiel',
        'Prämisse',
        'Schlussfolgerung',
        'ArgumentationFremd',
      ].includes(d.annotation.category)
    ) as element}
      <text
        x={innerWidth / 2}
        y={element.offsetTop + 0.5 * element.offsetHeight}
        dy={-11}
        style="stroke: {f(element.annotation.category)};"
        class="text-back">
        <!-- {element.annotation.category} -->
        {$_(element.annotation.category)}
      </text>
      <text
        x={innerWidth / 2}
        y={element.offsetTop + 0.5 * element.offsetHeight}
        dy={-11}>
        <!-- {element.annotation.category} -->
        {$_(element.annotation.category)}
      </text>
      {#if element.annotation.parent}
        {#each element.annotation.parent.topics as topic}
          <g
            transform="translate({(c
              .domain()
              .indexOf(topic) * innerWidth) / c.domain().length}, {element.offsetTop})">
            <circle
              cx={(0.5 * innerWidth) / c.domain().length}
              cy={0.5 * element.offsetHeight}
              r={7} />
            <circle
              cx={(0.5 * innerWidth) / c.domain().length}
              cy={0.5 * element.offsetHeight}
              r={4}
              class="circle-front"
              style="stroke: {c(topic)}" />
          </g>
        {/each}
      {:else if element.annotation.category === 'ArgumentationFremd'}
        {#each element.annotation.topics as topic}
          <g
            transform="translate({(c
              .domain()
              .indexOf(topic) * innerWidth) / c.domain().length}, {element.offsetTop})">
            <circle
              cx={(0.5 * innerWidth) / c.domain().length}
              cy={0.5 * element.offsetHeight}
              r={7} />
            <circle
              cx={(0.5 * innerWidth) / c.domain().length}
              cy={0.5 * element.offsetHeight}
              r={4}
              class="circle-front"
              style="stroke: {c(topic)};" />
          </g>
        {/each}
      {:else}
        {console.log('ERROR: no parent defined for Annotation', element)}
      {/if}
    {/each}

  </g>

</svg>
