<script>
  import { spring } from 'svelte/motion'

  export let pct
  export let item
  export let fill
  export let width

  $: rectWidth = spring(0, {
    stiffness: 0.1,
  })

  $: $rectWidth = pct
</script>

<rect
  y={item.y0}
  width={Math.max(0, $rectWidth * width)}
  height={item.y1 - item.y0}
  {fill}
  stroke="rgb(0, 21, 30)"
  style="stroke-width: 2;"
  rx={5}
  ry={5} />
