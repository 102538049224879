<script>
  import { links2, filter5, filteredLinks5 } from './stores2'
  import { group } from 'd3-array'
  import { getContext } from 'svelte'
  import { stacked, stacked3 } from './common'
  import TweenedRect from './TweenedRect.svelte'
  import { wordwrap } from 'd3-jetpack'
  import { _ } from 'svelte-i18n'

  export let category
  export let charsForHeight = false // if true, uses the length of characters to determine height

  $: width = getContext('width')

  let hover

  const RATIO = 1
  const MIN_HEIGHT = 24
  const MAX_LENGTH = 20
  const MAX_CHARS = 20

  $: itemCount = group(
    $filteredLinks5.discourses.filter((d) => d.category === category),
    (d) => d.type
  )

  $: originalItems = group(
    $links2.discourses.map(d => ({
      ...d,
      localizedType: $_(d.type)
    })).filter((d) => d.category === category),
    (d) => d.type
  )

  $: items = charsForHeight ? stacked3(originalItems, itemCount, MAX_CHARS) : stacked(originalItems, itemCount)
</script>

<style>
  text {
    font-size: 12px;
    dominant-baseline: middle;
    text-anchor: middle;
    user-select: none;
  }

  tspan {
    font-style: italic;
  }

  rect {
    stroke-width: 2;
    stroke: rgb(0, 21, 30);
    opacity: 0.3;
  }

  .item {
    cursor: pointer;
  }
</style>

<g>
  {#each items as item, i}
    <g
      class="item"
      on:click={() => {
        let tmp = $filter5
        tmp.discourse[category] = tmp.discourse[category] === item[0] ? undefined : item[0]
        $filter5 = tmp
      }}
      on:mouseover={() => (hover = true)}
      on:mouseout={() => (hover = false)}>
      <rect
        y={item.y0}
        {width}
        height={item.y1 - item.y0}
        fill={!itemCount.has(item[0]) ? '#061e28' : $filter5.discourse[category] === item[0] ? 'orange' : 'hsl(30, 49%, 17%)'}
        rx={5}
        ry={5} />
      <TweenedRect
        pct={item.pct}
        {item}
        {width}
        fill={!itemCount.has(item[0]) ? '#061e28' : $filter5.discourse[category] === item[0] ? 'orange' : 'hsl(30, 49%, 17%)'} />
      <!-- {#if item[0].length > MAX_LENGTH}
        <text
          x={hover ? 320 / 2 : width / 2}
          y={item.y0 + (item.y1 - item.y0) / 2}
          fill={itemCount.has(item[0]) ? 'white' : 'rgba(255, 255, 255, 0.2)'}>
          {hover ? item[0] : item[0].substring(0, 15) + '...'}{' '}
          <tspan>
            {itemCount.has(item[0]) ? itemCount.get(item[0]).length : ' '}
          </tspan>
        </text>
      {:else} -->
      {#if charsForHeight}
      <text
      fill={itemCount.has(item[0]) ? 'white' : 'rgba(255, 255, 255, 0.2)'}>
      {#each wordwrap($_(item[0]), MAX_CHARS) as token, t}
      <tspan>
        <tspan x={width / 2} y={(-((wordwrap($_(item[0]), MAX_CHARS).length - 1) / 2) * 16) + item.y0 + (item.y1 - item.y0) / 2} dy={t * 16}>{token}
        {#if t === wordwrap($_(item[0]), MAX_CHARS).length - 1}
        {' '}{itemCount.has(item[0]) ? itemCount.get(item[0]).length : ' '}
        {/if}
        </tspan>
      </tspan>
      {/each}
    </text>
      {:else}
      <text
        x={width / 2}
        y={item.y0 + (item.y1 - item.y0) / 2}
        fill={itemCount.has(item[0]) ? 'white' : 'rgba(255, 255, 255, 0.2)'}>
        {$_(item[0])}{' '}
        <tspan>
          {' '}{itemCount.has(item[0]) ? itemCount.get(item[0]).length : ' '}
        </tspan>
      </text>
      {/if}
    </g>
  {/each}
</g>
