<script>
  import CategorySequence from './../CategorySequence.svelte'
  import Login from './../Login.svelte'

   const margin = {
    top: 70,
    right: 100,
    bottom: 50,
    left: 20,
  }
</script>

<CategorySequence {margin} />
<Login />