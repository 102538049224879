<script>
  import { loginAllowed } from './stores2'
  import { fade } from 'svelte/transition'
  import { isUserLoggedOn } from './media-wiki'

  // const MEDIA_WIKI_LOGIN_URL =
  //   document.location.protocol +
  //   '//dbis-digivis.uibk.ac.at/mediawiki/index.php/Special:UserLogin'

  const MEDIA_WIKI_LOGIN_URL =
    'https://dbis-digivis.uibk.ac.at/mediawiki/index.php/Special:UserLogin'

  let loginOverlay
  let comment
  let overlayVisible = false

  function closeOverlayAndResubmit() {
    overlayVisible = !overlayVisible

    // loginOverlay
    // let comment = $('#commentField').val()
    console.log(comment)
    // preprocessComment('WriteCommentToMediaWiki', comment)
  }

  console.log(
    isUserLoggedOn() //.then((response) => console.log(response.ok, response))
  )
</script>

<style>
  .loginOverlay {
    position: absolute;
    top: 0px;
    right: 20px;
  }

  button {
  }

  iframe {
    border: none;
    width: 550px;
    height: 750px;
    border-radius: 3px;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    right: 150px;
  }
</style>

{#if $loginAllowed}
  <!-- <div class="login">
    <button>login</button>
  </div> -->
  <div bind:this={loginOverlay} class="loginOverlay" id="loginOverlay">
    {#if overlayVisible}
      <iframe transition:fade id="mw-login" src={MEDIA_WIKI_LOGIN_URL} />
    {/if}
    <button id="closeOverlay" on:click={() => closeOverlayAndResubmit()}>
      {#if !overlayVisible}show login{:else}hide login{/if}
    </button>
  </div>
{/if}
