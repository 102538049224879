<script>
  import {
    links2,
    filter5,
    filteredLinks5,
    annotations2,
    selectedText,
  } from './stores2.js'
  import { group } from 'd3-array'
  import { wordwrap } from 'd3-jetpack'
  import { fade } from 'svelte/transition'
  import { _ } from 'svelte-i18n'

  // TODO: discourses or argumentations?
  $: itemCount = group($filteredLinks5.discourses, (d) => d.page_id)

  $: items = Array.from(group($links2.discourses, (d) => d.page_id))
    .sort((a, b) => a[1][0].title.localeCompare(b[1][0].title))
    // .sort((a, b) => a[1][0].title.length - b[1][0].title.length)
    .reduce((acc, cur, i) => {
      const tokens = wordwrap(cur[1][0].title, 50)

      if (i === 0) {
        cur.y0 = 0
        cur.y1 = tokens.length * 20
      } else {
        cur.y0 = acc[i - 1].y1
        cur.y1 = acc[i - 1].y1 + tokens.length * 20
      }

      cur.tokens = tokens

      cur.annotations = $annotations2.filter(
        (d) =>
          ![
            'AntwortGlasersfeld',
            'Prämisse',
            'Beispiel',
            'Schlussfolgerung',
          ].includes(d.category) && d.page_id === +cur[0]
      )

      acc.push(cur)

      return acc
    }, [])

  $: isSelected = (annotation) =>
    annotation.topics.includes($filter5.argumentations.topic) ||
    annotation.innovation_types.includes($filter5.discourse.innovation) ||
    annotation.reference_types.includes($filter5.discourse.reference) ||
    annotation.narrative_types.includes($filter5.discourse.narrative)

  const GLASERSFELD_ANSWER_CHAR_LENGTH = 350000
  // gets the text length as defined, except for text 2293, for which the counter arguments are subtracted from the text
  function getTextLength(item) {
    if (item[0] === '2293') {
      return item.annotations[0].text.length - GLASERSFELD_ANSWER_CHAR_LENGTH
    } else {
      return item.annotations[0].text.length
    }
  }
</script>

<style>
  text {
    font-size: 12px;
    alignment-baseline: text-before-edge;
    cursor: pointer;
  }

  tspan {
    alignment-baseline: text-before-edge;
  }

  .scientific-reference {
    fill: rgb(89 60 34);
  }

  .argumentation {
    fill: rgb(29 65 79);
  }

  .selected {
    fill: orange;
  }
</style>

<g>
  {#each items.sort((a, b) => a.annotations[0].text.publication_date - b.annotations[0].text.publication_date) as item, i}
    <g>
      <g
        opacity={itemCount.has(item[0]) ? 1 : 0.2}
        transform="translate({300 - Math.min(getTextLength(item) * 0.003, 400)},
        {i * 22})"
        on:click={() => {
          let tmp = $filter5
          tmp.discourse.page_id = tmp.discourse.page_id === item[0] ? undefined : item[0]
          $filter5 = tmp
        }}>
        <clipPath id={`clip-path-${i}`}>
          <rect
            width={getTextLength(item) * 0.003 - 3}
            height={22}
            rx={3}
            ry={3} />
        </clipPath>
        <rect
          width={Math.min(getTextLength(item) * 0.003, 400)}
          height={22}
          stroke={$filter5.discourse.page_id === item[0] ? 'orange' : 'rgb(0, 21, 30)'}
          fill="transparent"
          opacity={1}
          style="stroke-width: 2;"
          rx={3}
          ry={3} />
        {#each item.annotations as annotation}
          <rect
            class:scientific-reference={['WissenschaftlicheReferenz', 'Narrativ', 'Innovationsdiskurs'].includes(annotation.category)}
            class:argumentation={annotation.category === 'Argumentation'}
            class:selected={itemCount.has(item[0]) && isSelected(annotation)}
            x={annotation.offsetBegin * 0.003}
            width={(annotation.offsetEnd - annotation.offsetBegin) * 0.003}
            height={20}
            y={1}
            clip-path={`url(#clip-path-${i})`}
            stroke="rgb(0, 21, 30)"
            style="stroke-width: 0.3;" />
        {/each}
        {#if $filter5.discourse.page_id === item[0]}
          <g transition:fade>
            <text
              y={15}
              fill="white"
              style="pointer-events: none; text-anchor: end;">
              {#each item.tokens as token}
                <tspan x={getTextLength(item) * 0.003 - 3} dy={14}>
                  {token.trim()}
                </tspan>
              {/each}
            </text>
            <text
              x={getTextLength(item) * 0.003 - 3}
              dy={44 + (item.tokens.length - 1) * 14}
              fill="white"
              style="text-anchor: end; dominant-baseline: central; font-style:
              italic; ">
              {item.annotations[0].text.publication_date}
            </text>
            <g
              on:click={() => {
                $selectedText = +$filteredLinks5.discourses[0].page_id
                let tmp = $filter5
                tmp.discourse.page_id = tmp.discourse.page_id === item[0] ? undefined : item[0]
                $filter5 = tmp
              }}
              style="cursor: pointer;">
              <!-- TODO: very specific workaround to make sure the button fits -->
              <g
                transform="translate({item[0] === '836' ? 0 : -70}, {item[0] === '836' ? 26 : 0})">
                <rect
                  width={60}
                  height={22}
                  stroke="orange"
                  fill="orange"
                  rx={3}
                  ry={3}
                  style="stroke-width: 0.5; fill-opacity: 0.1; stroke-opacity:
                  0.5;" />
                <text
                  dx={30}
                  dy={1}
                  fill="orange"
                  style="text-anchor: middle; font-size: 12px;">
                  {$_('read_the_text') + '...'}
                </text>
              </g>
            </g>
          </g>
        {/if}
      </g>
    </g>
  {/each}
</g>
