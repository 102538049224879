<script>
  import { onMount, onDestroy, getContext } from 'svelte'
  import {
    textHeight,
    textElement,
    glasersfeldTexts,
    nonGlasersfeldTexts,
    annotations2,
    selectedText,
    annotationElements,
    selectedTextCollection,
    loginAllowed,
  } from './stores2.js'
  import { _ } from 'svelte-i18n'

  export let text

  let el

  const c = getContext('c')

  function addCommentButton(div, annotation) {
    const commentContainer = document.createElement('div')
    commentContainer.className = 'comment-container'

    if ($loginAllowed) {
      const commentButton = document.createElement('button')
      commentButton.textContent = 'add comment'
      commentButton.className = 'comment-button'
      commentButton.addEventListener('click', () =>
        alert(
          `NOT IMPLEMENTED YET...\n Adding comment for title: ${annotation.text.title} and annotation_id: ${annotation.annotation_id}`
        )
      )
      commentContainer.appendChild(commentButton)
    }

    const toggleCommentsButton = document.createElement('button')
    toggleCommentsButton.textContent = `show ${Math.ceil(
      Math.random() * 6
    )}  comments`
    toggleCommentsButton.className = 'toggle-comments'
    // commentContainer.append(toggleCommentsButton) // TODO: implement this

    div.append(commentContainer)
  }

  $: annotations = $annotations2.filter((d) => d.page_id === $selectedText)
  $: console.log(annotations)
  onMount(() => {
    $textHeight = el.scrollHeight
    $textElement = el

    const offsets = []

    for (let div of el.getElementsByTagName('div')) {
      let annotation = annotations.find((d) => d.annotation_id === div.id)

      if (annotation) {
        if (annotation.category === 'Argumentation') {
          const topicTags = document.createElement('div')
          topicTags.className = 'topic-tags'
          annotation.topics.forEach((d) => {
            const tag = document.createElement('div')
            tag.className = 'tag-topic'
            tag.textContent = $_(d)
            tag.style.background = c(d)
            topicTags.appendChild(tag)
          })
          div.insertBefore(topicTags, div.childNodes[0])
        }
        if (annotation.category === 'ArgumentationFremd') {
          const topicTags = document.createElement('div')
          topicTags.className = 'topic-tags'
          annotation.topics.forEach((d) => {
            const tag = document.createElement('div')
            tag.className = 'tag-topic'
            tag.textContent = $_(d)
            tag.style.background = c(d)
            topicTags.appendChild(tag)
          })

          div.insertBefore(topicTags, div.childNodes[0])

          const answer = $annotations2.find(
            (a) => a.relatedCounterArgument === annotation.annotation_id
          )

          if (answer) {
            const answerEl = document.createElement('div')

            const textRef = document.createElement('span')
            textRef.textContent = answer.text.title
            textRef.className = 'glasersfeld-text-title'

            answerEl.textContent = answer.quote
            answerEl.appendChild(textRef)
            answerEl.className = 'glasersfeld-answer'
            div.appendChild(answerEl)
          }
        } else if (annotation.category === 'WissenschaftlicheReferenz') {
          annotation.reference_type
            .split(';')
            .filter((d) => d !== '')
            .forEach((d) => {
              const tag = document.createElement('div')
              tag.className = 'tag-reference'
              tag.textContent = $_(d)
              div.appendChild(tag)
            })
        } else if (annotation.category === 'Innovationsdiskurs') {
          annotation.innovation_type
            .split(';')
            .filter((d) => d !== '')
            .forEach((d) => {
              const tag = document.createElement('div')
              tag.className = 'tag-innovation'
              tag.textContent = $_(d)
              div.appendChild(tag)
            })
        } else if (annotation.category === 'Narrativ') {
          annotation.narrative_type
            .split(';')
            .filter((d) => d !== '')
            .forEach((d) => {
              const tag = document.createElement('div')
              tag.className = 'tag-narrative'
              tag.textContent = $_(d)
              div.appendChild(tag)
            })
        }

        if (
          [
            'Argumentation',
            'ArgumentationFremd',
            'WissenschaftlicheReferenz',
            'Innovationsdiskurs',
            'Narrativ',
          ].includes(annotation.category)
        ) {
          addCommentButton(div, annotation)
        }

        const offset = {
          offsetTop: div.offsetTop,
          offsetHeight: div.offsetHeight,
          annotation,
        }

        offsets.push(offset)
      }
    }

    $annotationElements = offsets
  })

  textHeight.subscribe(() => {
    if (el) {
      const offsets = []

      for (let div of el.getElementsByTagName('div')) {
        let annotation = annotations.find((d) => d.annotation_id === div.id)

        if (annotation) {
          const offset = {
            offsetTop: div.offsetTop,
            offsetHeight: div.offsetHeight,
            annotation,
          }

          // if (annotation.category === 'WissenschaftlicheReferenz') {
          //   div.className += ` ${annotation.reference_type}`
          // }

          offsets.push(offset)
        }
      }

      $annotationElements = offsets
    }
  })

  onDestroy(() => {
    $textHeight = undefined
    $textElement = undefined
    $annotationElements = undefined
  })
</script>

<div bind:this={el}>
  {@html text}
</div>
