import { wordwrap } from 'd3-jetpack'
import { _ } from 'svelte-i18n'

const RATIO = 1
const MIN_HEIGHT = 24

export function stacked (originalItems, itemCount) {
  return Array.from(originalItems)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .reduce((acc, cur, i) => {
      if (i === 0) {
        cur.y0 = 0
        cur.y1 = Math.max(
          MIN_HEIGHT,
          (originalItems.has(cur[0]) ? originalItems.get(cur[0]).length : 0) *
            RATIO
        )
        cur.pct = itemCount.has(cur[0])
          ? itemCount.get(cur[0]).length / originalItems.get(cur[0]).length
          : 0
      } else {
        cur.y0 = acc[i - 1].y1
        cur.y1 =
          acc[i - 1].y1 +
          Math.max(
            MIN_HEIGHT,
            (originalItems.has(cur[0]) ? originalItems.get(cur[0]).length : 0) *
              RATIO
          )
        cur.pct = itemCount.has(cur[0])
          ? itemCount.get(cur[0]).length / originalItems.get(cur[0]).length
          : 0
      }

      acc.push(cur)
      return acc
    }, [])
}

export function stacked2 (arr) {
  return Array.from(arr)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .reduce((acc, cur, i) => {
      const positions = {
        topic: cur[0],
        length: cur[1].length,
      }
      if (i === 0) {
        positions.y0 = 0
        positions.y1 = Math.max(MIN_HEIGHT, cur[1].length * RATIO)
        positions.cy = (positions.y0 + positions.y1) / 2
      } else {
        positions.y0 = acc[i - 1].y1
        positions.y1 =
          acc[i - 1].y1 + Math.max(MIN_HEIGHT, cur[1].length * RATIO)
        positions.cy = (positions.y0 + positions.y1) / 2
      }

      acc.push(positions)
      return acc
    }, [])
    .reduce((acc, cur) => {
      acc[cur.topic] = cur
      return acc
    }, {})
}


export function stacked3 (originalItems, itemCount, maxChars) {
  return Array.from(originalItems)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .reduce((acc, cur, i) => {
      const tokens = wordwrap(cur[1][0].localizedType, maxChars)

      if (i === 0) {
        cur.y0 = 0
        cur.y1 = Math.max(MIN_HEIGHT, tokens.length * 20)
        cur.pct = itemCount.has(cur[0])
          ? itemCount.get(cur[0]).length / originalItems.get(cur[0]).length
          : 0
      } else {
        cur.y0 = acc[i - 1].y1
        cur.y1 =
          acc[i - 1].y1 +
          Math.max(MIN_HEIGHT, tokens.length * 20)
        cur.pct = itemCount.has(cur[0])
          ? itemCount.get(cur[0]).length / originalItems.get(cur[0]).length
          : 0
      }

      acc.push(cur)
      return acc
    }, [])
}
